import React from "react";
import './REQ.css';

class REQS extends React.Component {
    render() {
        return (
            <article className="article br3 ba b--black-10 w-100 w-50-m w-50-l mw7 shadow-5 center">
                <main className="pa4 black-80">
                    <div className="measure">
                        <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
                            <div className='legend center'>
                                <legend className="f1 fw6 ph0 mh0">ايه المطلوب للدراسة</legend>
                            </div>
                            <hr/>

                            <div className="ui form ma0">
                                <div className="fields">
                                    <h3>
                                        ردا عالسؤال المكرر (أسافر ألمانيا أزاي)
                                        <br/>
                                        جواب السؤال ده بيحتاج لايام نكتب فيها لكن هلخص النقط بشكل سريع جدا في ٤ مراحل ركزوا كويس فيهم.
                                        <br/>
                                        ----------------------------------------------------
                                        <br/>
                                        المرحله الأولى
                                        <br/>
                                        ١- فكرة السفر عموما
                                        <br/>
                                        انك تبدأ تفكر في أنك محتاج في مرحلة ما تكمل طريقك برا و تبدأ من نقطة الصفر انك تشوف هتسافر فين و ازاي و الاهم ليه و هتعمل ايه و ازاي.
                                        <br/>
                                        ----------------------------------------------------
                                        <br/>
                                        ٢-الدافع
                                        <br/>
                                        ده البنزين اللي بيشغلك طول رحلتك الصعبه جدا جدا لو الدافع بتاعك مش قوي جدا انسى انك تسافر لان الوقت طويل و الاجراءات كتير و التعقيدات اكتر و نفسيتك هتتعب فلو عندك دافع قوي جدا جدا ماشي غير كدا بلاش تبدأ.
                                        <br/>
                                        ----------------------------------------------------
                                        <br/>
                                        ٣-شبكة المعلومات
                                        <br/>
                                        بما اننا بنتكلم عن المانيا فانت هتبدأ تكون شبكة معلومات و علاقات، تدخل جروبات الدراسه زي ده و تبص على كل البوستات بكومنتاتها و تفهم، تتابع الناس اللي مسافره بالفعل و تحس ان عندهم معلومه و تسألهم في الكومنتات و تضيف الناس اللي مسافره منهم الودودين اللي ماعندهمش ضغط كبير و تخليهم للطوارئ علشان مايزهقش منك لان حياته صعبه في الغربه فراعيه.
                                        <br/>
                                        ----------------------------------------------------
                                        <br/>
                                        ٤-لحظة الصفر
                                        <br/>
                                        بعد ماخلاص اللي فات خد حقه معاك بتاع كام شهر و استوعبت كويس انك داخل على مطحنه و مخاطره يا تظبط يا لا و متحمل نتيجة قرارك و اللي هيترتب عليه تبدأ فورا تنفذ بدون تردد و اجراء بيسلم اجراء.
                                        <br/>
                                        ----------------------------------------------------
                                        <br/>
                                        المرحلة التانية
                                        <br/>
                                        ٥-تحديد الدراسة
                                        <br/>
                                        شوف هتدرس ايه و تبدأ تدور على الجامعات اللي بتدرس المجال بتاعك و الطريقه في البوست المثبت، و تشوف مؤهلاتك ترشحك للقبول في الجامعه دي بعد ما تقارنها بمتطلباتهم او تراسل الجامعة لو عندك سؤال معين.
                                        <br/>
                                        بعد كدا بتشوف التقديم امتى و ازاي.
                                        <br/>
                                        ٦-تجهيز الاوراق و التقديم
                                        <br/>
                                        في بوست متطلبات التقديم للجامعات في البوست المثبت هتحضر الورق ده و تقدمه بالطريقه اللي الجامعه محدداها و دي ارخم مرحله لانك بتتعب في تجهيز الورق و بتتعب نفسيا بسبب الانتظار و كثرة الاجراءات، و بعد مابتقدم بتنتظر رد الجامعات عليك
                                        ----------------------------------------------------
                                        <br/>
                                        المرحله التالته (الفيزا)
                                        <br/>
                                        ٧-تحديد موعد مع السفاره
                                        <br/>
                                        ده معاد مقابلة الفيزا و تقديم الاوراق و يستحسن تحدده قبل المعاد اللي انت عايزه بفتره لان المواعيد عليها ضغط و بتخلص بسرعه و تحجز  معاد مقابله قبل المعاد اللي عايز تسافرله ب ٣ شهور
                                        <br/>
                                        ٨-الحساب البنكي
                                        <br/>
                                        كل الفتره اللي فاتت بتتختم معاك بقبول الجامعه و بتبدأ مرحلة الفيزا بانك تفتح الحساب البنكي المغلق في المانيا و ده موجود بالبوست المثبت و بعد مايتفتح بتحول الفلوس و تستنى يبعتولك كونفيرم خلال اسبوع ان تم وصول مبلغ كذا و اتعمله بلوك
                                        <br/>
                                        ٩- ابلكيشن السفارة 
                                        <br/>
                                        ده ابلكيشن الفيزا و معاه الاقرار الامني بتنزلهم ظن موقع السفاره و تملاهم نسختين كل واحده و تمضيه
                                        <br/>
                                        ١٠- بتروح مقابلتك في اليوم المحدد معاك الأتي
                                        <br/>
                                        قبول الجامعه + الحساب البنكي + ورقة تأكيد وصول الفلوس لهم+شهادة لغة الدراسة سواء ايلتس او الماني+ ابلكيشن الفيزا+الاقرار الامني+شهاة الثانوية + شهادة البكالوريوس (ان وجد) + صور شخصيه بمقاسات هتلاقيها على موقع السفاره
                                        <br/>
                                        ----------------------------------------------------
                                        <br/>
                                        المرحلة الرابعه
                                        <br/>
                                        باذن الله يجيلك القبول بتروح تعمل تأمين سفر ٦ شهور من اي شركة تأمين سياحي و ليكن الينز و توديه مع الباسبور في ايام تسليم الباسبورات و تنتظر يومين يدوك الباسبور عليه الفيزا.
                                        <br/>
                                        بتطلع تصريح و تحجز طيران و تتوكل على الله و يبقى لينا بوست من المطار للجامعه و الحياه هناك
                                        <br/>
                                        ----------------------------------------------------
                                        <br/>
                                        تنبيه : المعلومات الوارده هنا مكتوبه و تقرأ في دقائق و لكن تنفيذها قد يحتاج سنه و يحتمل فيها الفشل او النجاح و هذه الفتره هي اصعب فترة تمزق نفسي للمتقدم
                                    </h3>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </main>
            </article>
        );
    }
}

export default REQS;
