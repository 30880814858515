import React from "react";
import one from './pics/one.jpeg';
import two from './pics/two.jpeg';
import './WiedervereinigungsVisum.css';

class WiedervereinigungsVisum extends React.Component {
    render() {
        return (
            <article className="article br3 ba b--black-10 w-100 w-50-m w-50-l mw7 shadow-5 center">
                <main className="pa4 black-80">
                    <div className="measure">
                        <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
                            <div className='legend center'>
                                <legend className="f1 fw6 ph0 mh0">فيزا لم الشمل</legend>
                            </div>
                            <hr/>

                            <div className="ui form ma0">
                                <div className="fields">
                                    <div class="row">
                                        <div class="column">
                                            <img alt='' width='250px' height='400px' src={one} />
                                        </div>
                                        <div class="column">
                                            <img alt='' width='250px' height='400px' src={two} />
                                        </div>
                                    </div>
                                    <hr/>

                                    <h3>
                                        النهاردة هتكلم عن فيزا لم الشمل و شروطها 
                                        <br/>
                                        لم االشمل إحدى قوانين الإندماج في المانيا والذي يهدف إلى جمع شمل الأشخاص المقيمين في المانيا بذويهم المقيمين خارج المانيا، ويشترط القانون على من يرغب في شمله بأسرته في المانيا أن يكون حاصلاً على حق الإقامة في المانيا سواء عن طريق الهجرة والعمل في المانيا، أو عن طريق اللجوء في المانيا، أو للمقيمين في المانيا لأي سبب كان
                                        <br/>
                                        ،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،
                                        <br/>
                                        = من يمكنه التقدم بطلب لم شمل الأسرة في المانيا
                                        <br/>
                                        = يجب أن تتوفر لدي هؤلاء الأشخاص الشروط التالية
                                        <br/>
                                        1 _ أن يكون الشخص المتقدم بطلب لم شمل حاملاً للإقامة الألمانية 
                                        <br/>
                                        2 _ يجب أن يقدم الشخص الذي يرغب بالتقدم بطلب لم الشمل في المانيا ما يثبت أنه يستطيع إعالة نفسه وإعالة من يرغب في جلبهم للعيش معه في المانيا،.
                                        <br/>
                                        3 _ أن يقدم الشخص ما يثبت أن لديه منزل يكفيه ويكفي من يرغب في لم شمله
                                        <br/>
                                        ،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،
                                        <br/>
                                        = من يمكنه التقدم  بطلب لم الشمل في المانيا 
                                        <br/>
                                        1 _ يمكن للزوجة التقدم بطلب لم شمل لزوجها في المانيا وكذلك العكس
                                        <br/>
                                        2 _ يمكن للزوج أو الزوجة التقدم بطلب جمع شملهم مع أطفالهم في المانيا شرط أن يكون الطفل الذي يرغب الزوج أو الزوجة بلم شمله لم يتجاوز سن 18 عاماً
                                        <br/>
                                        3 _ الزوجة أو الزوج الذي يرغب في لم شمله طفله في المانيا يجب أن يكون وصياً على الطفل، واذا كان أحد الشريكين مقيم في المانيا والشريك الآخر مقيم خارج المانيا ولكنهم لهم نفس حق الوصاية على الطفل يجب على الشريك المقيم خارج المانيا أن يوافق على لم شمل الطفل في المانيا مع شريكه الآخر.
                                        <br/>
                                        4 _ الأزواج الراغبين في لم شملهم في المانيا يجب  أن تكون أعمارهم فوق 18 عاماً عند التقدم بطلب لم ويجب على الشخص القادم لألمانيا سواء الزوج أو الزوجة تقديم ما يثبت إتقان اللغة الألمانية " المستوى الاول"
                                        <br/>
                                        5 _ يمكن للطفل التقدم بطلب للم شمله مع أبيه أو أمه أو كليهما ولكن بشرط أن يكون الطفل لم يتجاوز الطفل سن 18 عاماً.
                                        <br/>
                                        6 _ الشخص الذي يدرس في المانيا بعد أن يستقر في المانيا يمكنه التقدم بطلب لم شمل لشريكه  
                                        <br/>
                                        و يجب توافر الشروط التالية
                                        <br/>
                                        1. أن يقدم مايثبت أنه لديه السكن والقدرة على إعالة الشريك عند قدوم الشريك للإقامة في المانيا.
                                        <br/>
                                        2. أن يكون الشريكين قد تزوجا قبل حصول الشريك الأول على إقامة الطالب للدراسة في المانيا.
                                        <br/>
                                        3. أن يثبت الشريك الذي ينوي القدوم للإقامة في المانيا مع شريكه اتقانه للغة الالمانية " المستوى الأول ".
                                        <br/>
                                        4. في حال كان زواج الشخص المقيم في المانيا تم بعد بعد السفر إلى المانيا يمكن أيضاً  التقدم بطلب للم شمل للشريك المقيم خارج المانيا ولكن يجب على الشريك المقيم في المانيا أن يكون قد مضى على الفترة التى قضاها في المانيا عامين وأن تكون الإقامة التى يحملها الشريك المقيم في المانيا صالحة للتمديد.
                                        <br/>
                                        ،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،
                                        <br/>
                                        = الاوراق المطلوبة فى مقابلة السفارة بغرض لم الشمل"  الزوج المصرى او الالمانى"
                                        <br/>
                                        هتلاقيها في الصورة بس انا هوضح هنا الحاجات اللى ممكن البعض يسأل عنها
                                        <br/>
                                        1- <a target='_blank' rel='noreferrer' href='https://kairo.diplo.de/contentblob/4391688/Daten/4776946/downloaddatei_visaantrag_langzeitvisum_eng_arab.pdf'>ابلكيشن السفارة</a>
                                        <br/>
                                        2- و متنسوش ال<a target='_blank' rel='noreferrer' href='https://kairo.diplo.de/contentblob/866046/Daten/24366/download_biopaesse_fotomustertafel.pdf'>3 صور الشخصية</a>
                                        <br/>
                                        3- <a target='_blank' rel='noreferrer' href='https://kairo.diplo.de/contentblob/4002944/Daten/6869211/rk_visa_belehrungsblatt_fuer_schengenvisum_eng.pdf'>الاقرار القانوني</a> وقع عليه وامضي فقط
                                        <br/>
                                        ،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،
                                        <br/>
                                        = معلومات مهمة
                                        <br/>
                                        -  لو انت فى البكالوريوس اوالماستر شرط اساسي الحساب المغلق 8640 يورو  للزوج/ـة
                                        <br/>
                                        لو بتشتغل كطالب فتقدر تقدم عقد العمل ومفردات المرتب ويشوف مكتب الاجانب هيقلل الفلوس والا لاء
                                        <br/>
                                        - كل طفل  "اقل من 18 سنة" لازم حساب مغلق و قيمته تبقا النص 
                                        <br/>
                                        - الدكتوراة بتختلف من واحد لواحد  " لو هتشتغل " و ممكن متحتجش حساب مغلق للى هتجيبهم لم شمل 
                                        <br/>
                                        - لو الزوج معاه البلو كارد بيكون الراتب كافي للاسره فمفيش حساب مغلق للزوجة والا الأطفال واقامه العمل العادية حسب المرتب
                                        <br/>
                                        - مفيش لم شمل للاخوات و ممكن يبقا للوالد او الوالدة بس بيشوفوا الحالة لو تستدعى يعنى مش اساسى  
                                        <br/>
                                        لم الشمل فقط للزوج/ــة و الاطفال 
                                        <br/>
                                        لازم ترجع لمكتب الاجانب هو اللى بيحدد مساحة السكن و بيحدد الفلوس 
                                        <br/>
                                        لان كل مدينة ممكن تختلف عن اى مدينة تانية 
                                        <br/>
                                        - اللى بيطلع لم شمل له الحق ان هو يدرس و يشتغل من غير اى قيود 
                                        <br/>
                                        ،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،،
                                        <br/>
                                        =  تجربيتن للم الشمل 
                                        <br/>
                                        - التجربة الاولى 
                                        <br/>
                                        بدايه كده لم الشمل ممكن يتعمل ب طريقتين،،ياما شخص يسافر ويعمل للتاني لم شمل،،،،،ياما الاتنين يقدموا مره واحده ف السفاره يحجزوا معادين مع بعض(ايام فودافون كنت بحجز معاد للدراسه و زوجي بيحجز معاد لم شمل علي رقم الحجز بتاعي و بيدولوه معاد بعدي،ب ربع ساعه بس احنا مكملناش بنفس الطريقه)بحيث واحد يقدم علي الفيزا العاديه و التاني يعمل يقدم شمل و ف الحاله دي بيكونوا نفس file  ف السفاره(ضروري يبقوا نفس file,,ف واحد كلمني من السفاره مكامله نص ساعه يشرحلي فيها ده)
                                        <br/>
                                        و ف الحالتين نفس الورق بيبقي مطلوب
                                        <br/>
                                        = الورق المطلوب
                                        <br/>
                                        1- عقد سكن يكفي عدد الافراد بحيث 12 متر مربع صافي living area لكل شخص من غير الحمام و االبلكونه و المطبخ والحاجات دي
                                        <br/>
                                        2-حساب بنكي مغلق ف المانيا يكفي عدد الافراد و اللي بيحدد المبلغ مكتب الاجانب بتاع كل مدينه و بيختلف من مدينه للتانيه و بيتحسب علي اساس مصاريف الفرد ف الشهر من سكن و تامين و مصاريف معيشه وكده(طريقه حساباتهم انا مش متاكده منها،،بس غالبا بيطلبوا ياما 8640 للفرد التاني و دول المكاتب اللي مش بيحسبوها اصلا و بيطلبوا الضعف وخلاص اوبتبقي حوالي رينج 6000 مع معظم الناس التانيه)
                                        <br/>
                                        3- شهاده A1 للشخص اللي هيتعمل ليه لم شمل( و انا قريت انه لو شهاده جامعيه  تثبت ان الشخص كا بيدرس ب انجلش قريبا ممكن تغني او حاجه شبه كده انا مش متاكده)
                                        <br/>
                                        4- باقي الورق العادي م موقع السفاره،،اللي هو ابليكشن و اقرار امني صور الباسبور و الحاجات العاديه(و مطلبوش صوره اقامه ليا ولا حاجه)
                                        <br/>
                                        <br/>
                                        = الخطوات و المقابله
                                        <br/>
                                        انا تجربتي غريبه شويه ف ياريت محدش يتلخبط،،،انا زوجي قدم علي لم الشمل ف معاد منفصل و انا لسه فمصر و مكنتش الفيزا جت اصلا و مقدمش معايا زي ما قلت فوق،،،لا قدم لوحده وانا لوحدي(معرفش السفاره معترتضش ازاي انا كنت مستنيه يقولوه انت هتعمل لم شمل علي مين،،انت مراتك لسه ف مصر اصلا و معهاش الفيزا حتي!!!) و
                                        <br/>
                                        المهم هو راح بورق السفاره وشهاده اللغه والسفاره طلبت منه حساب بنكي مؤقت ليه من مصر ، 
                                        <br/>
                                        الموظفه سالته شويه بالالماني طبعا و سالته عني بدرس ايه و قاعده فين و كده و قالتله يعمل استكمال ب عقد السكن ،
                                        <br/>
                                        بعدها الفيزا جاتلي انا وسافرت(انا سافرت ومستنتش ف مصر لانه كان لازم عقد سكن و ده شبه مستحيل نجيبه و احنا ف مصر و حاولنا فيه يجي 3 شهور و مفيش امل)
                                        <br/>
                                        (بعد سفري ب شهر لقيت سكن و بعته ل زوجي عمل بيه استكمال)
                                        <br/>
                                        الخطوه اللي بعد كده الورق ده بيتبعت المانيا و من هنا كل تعاملهم كان معايا انا ف المانيا عن طريق مكتب الاجانب و السفاره ملهاش دعوه خلاص،،،
                                        <br/>
                                        المفروض مكتب الاجانب بيكلم الشخص يطلب منه كذا حاجه بس انا مستنتش و كنت كل شويه بزن عليهم(و دي كانت فعلا اسود فتره لانه انا ورقي اتركن يجي شهر و نص بدون داعي لان  كان قبل اجازه الكريسماس و بعدها الهانم  الموظفه المسئوله عني كانت اجازه ) بعد فتره بدوا يشتغلوا فالورق و طلبت مني اجيب لها عقد السكن و اثبات التامين الصحي وو ديتهولها عشان تحسب المبلغ اللي مفروض ازوده ف حسابي البنك و اعمل حساب مغلق جديد و ادتني معاد بعدها ب 3 اسابيع عشان تقولي ازود الحساب كام (مع ان الحسابات دي بتتعمل ف ثانيتين بالظبط)و اجيبه مع ان حساب البنك كان جاهز و واقف بس علي تحديد المبلغ اللي هيتعمله. Blocking لاننا كنا سابقين خطوه عشان نسرع الورق!!!! بس لا ازاي!! لان انا كان عندي معاد اصلا للاقامه بتاعي فقالتلي كله ف يوم واحد و مش هبص ف الورق قبل كده،،،
                                        <br/>
                                        وعشان اغير الحساب المغلق ادتني ورقه بالسبب ليه محتاجه اغير الحساب لان ف البنك ف بيطلبوا حاجه توضح ليه عاوزه اغير الحساب ،،
                                        <br/>
                                        ف وديت الورقه والبنك غير الحساب و عمل blocking علي حسب المبلغ اللي مكتب الجانب طلبه و بدخل،شهري بردو محدد من مكتب الاجانب و اخدوا 150 يورو تاني ف الحركه دي،،،
                                        <br/>
                                        بعد 3 اسابيع روحت وديت الورق
                                        <br/>
                                        المفروض كده الورق كله تمام و تمشي ورق جوزي ،،قالتلي لا لازم استني لما الاقامه بتاعتك تطلع الاول (يعني شهر كمان) قولتلها ف ناس الورق بيمشي عادي معاهم من غير ما الاقامه تتطلع و ده حصل مع صحابي فعلا قالتلي لا وكنت انا عاوزه انزل مصر لحد ما ورق جوزي يخلص و نرجع سوا قالتلي لا مش همشي ورق جوزك غير لما تستلمي مني اقامتك بايديك(اه والله بالحرف كده)
                                        <br/>
                                        (ادعوا عليها معايا يا جماعه لانها ورتني ايام سوده فعلا)
                                        <br/>
                                        بس و استنيت و استلمت الاقامه و نزلت مصر و فيزا بتاع جوزي طلعت بعدها بحاولي 3 اسابيع(معرفش ليه كل ده بردو!!!) 
                                        <br/>
                                        بس و دي كانت قصه كفاحنا 
                                        <br/>
                                        NB:
                                        <br/>
                                        1-انا معنديش اي خبره عن لم شمل للاطفال
                                        <br/>
                                        2-  احنا مشينا بالطريقه دي و مش مع بعض زي ما كان ممكن يحصل،،لان انا و زوجي قدمنا دراسه و احنا الاتنين وقلنا انه اللي هيجيله القبول الاول التاني هيعمل لم شمل عليه و دي الطريقه الاسرع و لما انا جالي الاول لغي هو مهاد الدراسه بتاعه و حجز لم شمل 
                                        <br/>
                                        3- كل كلامي يخص اللي بيدرسوا و عاوزين يعملو لم شمل مش ناس بتشتغل مثلا لان اكيد الوضع هيختلف بالنسبالهم ف موضوع حساب البنك عشان ممكن يقدموا ورق بالمرتب و كده
                                        <br/>
                                        .............................................................
                                        <br/>
                                        - التجربة الثانية
                                        <br/>
                                        أنا عملت تأشيرة لم شمل على مراتي وهي طالبة مصرية فى ألمانيا
                                        <br/>
                                        الموضوع مش ضروري يكون شغل 
                                        <br/>
                                        كافي جدا ان يكون عندك ما يكفي مصاريفك فى حساب مغلق أو مفتوح فى ألمانيا ( حساب بنفس مبلغ حساب الدراسه 8640 يورو ) 
                                        <br/>
                                        مكتب الأجانب فى بون
                                        <br/>
                                        طلب شوية أوراق زي مثلا اثبات ان ايجار الشقة بيتدفع من حساب مراتي عشان يتأكد ان الanmeldung مش وهمي ومستنش 6 شهور ولا حاجه مجرد ما عملت ال anmeldung بعتتهولي حتي قبل الاقامه بتاعتها ما تطلع وعملت مقابله السفاره.
                                        <br/>
                                        بس بشكل رئيسي كل اللى كان محتاجه اثبات سكن مساحة توازي 12 متر مربع لكل شخص 
                                        <br/>
                                        يعني لو فردين واحد ومراته يكفي 24 متر مربع
                                        <br/>
                                        اثبات قدرة على الانفاق زي الطالب زي المرافق
                                        <br/>
                                        ده هناك 
                                        <br/>
                                        هنا فى مصر عشان يبعتو الورق طلبو قيد عائلي و قسيمة جواز مترجمين ألماني وشهادة لغة A1 على الأقل وورقة من الstadthaus تثبت محل السكن
                                        <br/>
                                        حصلت عندي مشكلة أخرت ورقي شوية ان مراتي كانت فى سكن طلبة الأول وده كان مرفوض من مكتب الأجانب رغم ان المساحة متوافقة يعني لازم تكون الشقة ايجار بنفسك مش ايجار في سكن طلبه لانه مينفعش غير للطالب بسز
                                        <br/>
                                        معدل الايجارات فى بون تقريبا من 600 الى 700 شامل التدفئة والمية والكهرباء لمساحة تقريبا 40 متر مربع أو أكبر شوية
                                        <br/>
                                        تفصيلة اضافية ان حتى مش ضروري تستنى لحد ما الاقامة ما تطلع 
                                        <br/>
                                        مجرد ما تسجل نفسك فى مكتب الأجانب ممكن تبعت طلب التأشيرة وهايتعاملو معاه عادي جدا حتى من قبل ما يطلع كارنيه الاقامة
                                        <br/>
                                        اقامة مراتي طلعت بسنتين من غير مشاكل هي مدة الدراسة وغالبا اقامتي أنا كمان هاتطلع بنفس المدة
                                    </h3>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </main>
            </article>
        );
    }
}

export default WiedervereinigungsVisum;
