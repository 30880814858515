import React from "react";
import './TGS.css';

class TGS extends React.Component {
    render() {
        return (
            <article className="article br3 ba b--black-10 w-100 w-50-m w-50-l mw7 shadow-5 center">
                <main className="pa4 black-80">
                    <div className="measure">
                        <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
                            <div className='legend center'>
                                <legend className="f1 fw6 ph0 mh0">ازاى تحول تقديرك للتقدير الألماني</legend>
                            </div>
                            <hr/>

                            <div className="ui form ma0">
                                <div className="fields">
                                    <h3>
                                        ناس كتير الفترة اللى فاتت سألت ازاى تحسب المعدل بتاعها و ازاى تحوله للتقدير الألمانى و تعرف هى جايبة كام
                                        <br/>
                                        و المعدل الألماني يحسب باعتبار أعلى علامة هي 1 وأدنى علامة هي 5 و النجاح من 4 يعنى 1 معدل %100
                                        <br/>
                                        ______________________________________
                                        <br/>
                                        طريقة الحساب =
                                        <br/>
                                        ( المجموع كامل - مجموع الطالب ) / ( المجموع كامل - الحد الأدنى للنجاح " درجة النجاح" )
                                        <br/>
                                        ينتج رقم يتم ضربه بالعدد 3 و من ثم جمعه لـ " واحد " فيكون المعدل الألماني
                                        <br/>
                                        .......
                                        <br/>
                                        مثلا لو واحد جايب فى الثانوية 95% و النجاح من 50% و عايز يحسب هو معدله كام بالألمانى
                                        <br/>
                                        فالطريقة بتبقا
                                        <br/>
                                        ( 100 - 95 )
                                        <br/>
                                        /
                                        <br/>
                                        ( 100 - 50 )
                                        <br/>
                                        X 3
                                        <br/> 
                                        + 1
                                        <br/>
                                        ______________________________________
                                        <br/>
                                        = المعدل بالتقدير بالألمانى
                                        <br/>
                                        لو التقدير مش بالنسبة المئوية فى جامعات فـ عادى برضه
                                        <br/>
                                        ==
                                        <br/>
                                        بالنسبة للجامعة فكل واحد يعرف النجاح من كام
                                        <br/>
                                        فيه جامعات درجة النجاح فيها من %50
                                        <br/>
                                        و فيه جامعات تانية درجة النجاح من %60
                                        <br/>
                                        ==
                                        <br/>
                                        الناس اللى هتقدم بكالوريوس فبتحسب الثانوية و مجموع اول سنة جامعة
                                        <br/>
                                        فبتحسب دة لوحده و دة لوحده و بعد كدة بتجمعهم
                                        <br/>
                                        و بتقسم على 2 و ينتج المتوسط
                                        <br/>
                                        و الماستر تقريبا مالوش علاقة بالثانوية.. و ياريت الناس تتأكد برضه
                                        <br/>
                                        بالتوفيق يا شباب
                                    </h3>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </main>
            </article>
        );
    }
}

export default TGS;
