import React from "react";
import './Kosten.css';

class Kosten extends React.Component {
    render() {
        return (
            <article className="article br3 ba b--black-10 w-100 w-50-m w-50-l mw7 shadow-5 center">
                <main className="pa4 black-80">
                    <div className="measure">
                        <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
                            <div className='legend center'>
                                <legend className="f1 fw6 ph0 mh0">تكاليف المعيشة للطالب في ألمانيا </legend>
                            </div>
                            <hr/>

                            <div className="ui form ma0">
                                <div className="fields">
                                    <h3>
                                        ازاي تصرف ٣٥٠ يورو بس في الشهر كطالب جامعي في المانيا
                                        <br/>
                                        بوست يمكن ناس كتيير هتعترض عليه و تقول كلام فارغ بس خلينا نفهم الموضوع واحدة واحدة ...
                                        <br/>
                                        الاول لازم نعرف الطالب الجامعي بيصرف  فلوس في  ايه - و نركز على كلمة طالب جامعي مش طالب لغة - هنلاقي ان مصاريفه الاساسية متوزعة على ٥ حاجات ...
                                        <br/>
                                        ١- السكن و ده بياخد اكبر جزء من المصاريف عادة 
                                        <br/>
                                        ٢- الاكل و الشرب  
                                        <br/>
                                        ٣- التأمين الاجباري 
                                        <br/>
                                        ٤- المواصلات 
                                        <br/>
                                        ٥- باقة الموبايل
                                        <br/>
                                        انا عارف ان فيه مصاريف ممكن تكون غير كدة بس في البوست ده انا بتكلم عن المصاريف الاساسية اللي كلنا بنصرفها  و متفقين عليها  .
                                        <br/>
                                        عشان الواحد يصرف ٣٥٠ يورو في الحاجات دي كلها 
                                        <br/>
                                        محتاج :
                                        <br/>
                                        ١-السكن : يكون ايجاره ٢٠٠-٢٥٠ يورو في الشهر 
                                        <br/>
                                        ٢-الاكل يكون ٨٠-١٠٠
                                        <br/>
                                        ٣- لازم حد تاني يدفعله التأمين 
                                        <br/>
                                        ٤-كارت الجامعة يكون  شايل مصاريف المواصلات-في جامعات كارت الجامعة مش بيدي الحق في ركوب المواصلات مجانا -  او يشتري عجلة
                                        <br/>
                                        ٥- يقتصد في باقة الموبايل و لتكن ٨-٢٠ يورو شهريا
                                        <br/>
                                        التجربة اللي اتطبق عليها الموضوع ده توزيع مصروفها الشهري  : 
                                        <br/>
                                        ١- السكن : سكن جامعي ايجاره ٢٣٠ يورو 
                                        <br/>
                                        ٢- الاكل ٨٠-١٠٠ يورو 
                                        <br/>
                                        ٣- شغل دوام جزئي فصاحب الشغل بيدفع التامين
                                        <br/>
                                        ٤- مصاريف الجامعة في التيرم ١٢٠ يورو شاملة كارت مواصلات في المدينة كلها 
                                        <br/>
                                        ٥-باقة موبايل ١٣ يورو
                                        <br/>
                                        لو وصلت في البوست لهنا فانت غالبا الكلام بدأ يدخل دماغك  ....بس الاهم من التوزيع ده هو التخطيط لتنفيذه  و ده هقولك بيتعمل ازاي برضه
                                        <br/>
                                        الافضل يكون من قبل ما تسافر انك تكون محدد الجامعة و المدينة اللي متوفر فيها الكلام ده و طبعا بشكل ميتعارضتش مع التخصص اللي عايز تدرسه 
                                        <br/>
                                        ١- بالنسبة للسكن عشان تعرف ظروفه هتكتب على جوجل كلمة studentenwerk  وجنبها اسم المدينة او تكتب برضه studentenwohheime و برضه جنبها اسم المدينة و تشوف اسعار السكن الجامعي ايه لانك صعب تلاقي سكن في المدينة ارخص منه ولو عجبك السعر تبدا تسال الناس الي في المدينة دي عن مدى توفر السكن عشان في مدن سكنها الجامعي رخيص و بتلاقي ان السكن مش متوفر
                                        <br/>
                                        ٢-بالنسبة للاكل اسال الناس القديمة منين بيجيبوا الحاجات الرخيصة و الافضل و انت لما تيجي هتفضل تتابع العروض و تظبط  حالك و متخفش بشهادة ناس كتير ٨٠-١٠٠يورو معقولين جدا كأكل لفرد واحد في الشهر و اكيد متنساش تتعلملك طبختين كدة و لا حاجة.
                                        <br/>
                                        ٣- تسال الناس القديمة عن توافر الشغل في المدينة اللي انت رايحها و لو تقدر ظبط معاهم   انهم يشغلوك نفس الشغلانة اللي بيشتغلوها -بشرط طبعا ان صاحب الشغل بيدفع التأمين بتاعك
                                        <br/>
                                        ٤- حاول تختار جامعة مصاريفها  كل تيرم قليلة-موجود على موقع الجامعات مصاريف التيرم كام- و طبعا بتديك كارت مواصلات
                                        <br/>
                                        ٥-هات موبايلك و انت جاي  من مصر و اعمل باقة مش غالية لانك اصلا مش  هتكلم ناس كتير والنت موجود في كل حتة
                                        <br/>
                                        ______________________________________
                                        <br/>
                                        لينكات لتجارب زياده
                                        <br/>
                                        <div className='lft'>
                                            <a target='_blank' rel='noreferrer' href='https://www.facebook.com/groups/311111149267612/posts/351192345259492/'>facebook.com/../../posts/351192345259492</a>
                                        <br/>
                                            <a target='_blank' rel='noreferrer' href='https://www.facebook.com/groups/311111149267612/posts/967350283643692/'>facebook.com/../../posts/967350283643692</a>
                                        <br/>
                                            <a target='_blank' rel='noreferrer' href='https://www.facebook.com/groups/311111149267612/posts/527239300988128/'>facebook.com/../../posts/527239300988128</a>
                                        </div>
                                    </h3>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </main>
            </article>
        );
    }
}

export default Kosten;
