import React from "react";
import './Apply.css';

class Apply extends React.Component {
    render() {
        return (
            <article className="article br3 ba b--black-10 w-100 w-50-m w-50-l mw7 shadow-5 center">
                <main className="pa4 black-80">
                    <div className="measure">
                        <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
                            <div className='legend center'>
                                <legend className="f1 fw6 ph0 mh0">مواعيد و كيفية التقديم على الجامعة وما هو القبول المشروط و النهائى ؟</legend>
                            </div>
                            <hr/>

                            <div className="ui form ma0">
                                <div className="fields">
                                    <h3>
                                        بما ان فيه ناس كتير اشتكت ان فيه بوستات مبتفتحش فى البوست المثبت زى القبول النهائى و المشروط و مواعيد التقديم
                                        <br/>
                                        فـ انا حاولت على قد ما أقدر اجمع المعلومات اللى اعرفها و اقولها فى البوست دة و ان شاء الله تفيدكم 
                                        <br/>
                                        .....
                                        <br/>
                                        اولا
                                        <br/>
                                        الفصول الدراسية فى المانيا : مواعيد التقديم على الجامعات
                                        <br/>
                                        فى المانيا يوجد فصلين دراسين 
                                        <br/>
                                        فصل صيفى و بداية الدراسة فيه فى ابريل و ينتهى فى سبتمبر
                                        <br/>
                                        فصل شتوى و بداية الدراسة فيه فى اكتوبر و ينتهى فى مارس
                                        <br/>
                                        = مواعيد التقديم على الجامعات ؟
                                        <br/>
                                        لكل فصل دراسى مواعيد للتقديم عليه والمعروف دائما ان فترة التقيدم
                                        <br/>
                                        للفصل الصيفى
                                        <br/>
                                        من 15/11 ل 15/1
                                        <br/>
                                        و للفصل الشتوى
                                        <br/>
                                        من 15/5 ل 15/7
                                        <br/>
                                        لكن فيه بعض الجامعات اللى مبتلتزمش بالمواعيد دى و ممكن تمد الفترة شوية او تقللها او تغيرها خالص و فيه جامعات ممكن فيها التقديم طول السنة فالافضل مراسلة الجامعة و التأكد منها ..
                                        <br/>
                                        = ايه الفرق بين الفصلين ؟
                                        <br/>
                                        هتلاقى جامعات التقديم فيها للترم الشتوى فقط
                                        <br/>
                                        و دة يعنى البداية هيبقا فى الترم الشتوى و بعد كدة ترم صيفى وهكذا
                                        <br/>
                                        مش هتدرس كل سنة ترم شتوى بس فيه ناس فاكرة كدة ف وجب التوضيح
                                        <br/>
                                        و هتلاقى جامعات التقديم فيها ترم صيفى فقط
                                        <br/>
                                        و هتلاقى جامعات فيها التقديم ع الترمين يعنى لو قدمت ع الترم الصيفى فهتبدأ فى الصيفى و لو قدمت على الترم الشتوى فهتبدأ فى الشتوى
                                        <br/>
                                        - مفيش فرق خالص ما بين ان انت تبدأ في الترم الشتوى الأول او الصيفى الأول
                                        <br/>
                                        "كل جامعة وليها نظام خاص بيها"
                                        <br/>
                                        _______________________________
                                        <br/>
                                        ثانيا
                                        <br/>
                                        القبول النهائى و القبول المشروط =
                                        <br/>
                                        * القبول النهائى
                                        <br/>
                                        دة فيما معناه ان انت هتقدم على الجامعة و محقق شرط اللغة اللى الجامعة محتاجاه وهتبدأ دراسة مباشرة مش لسا هتدرس لغة 
                                        <br/>
                                        شهادات اللغة المطلوبة فى الجامعات لبدأ الدراسة ؟
                                        <br/>
                                        - لو الدراسة بالانجليزى
                                        <br/>
                                        - ielts or toefl
                                        <br/>
                                        الجامعات بتطلب الشهادتين وانت تحقق شهادة واحدة منهم و لكل شهادة درجة معينة الجامعة بتحددها فلازم تحققها" يفضل تسأل الجامعة على الدرجة"
                                        <br/>
                                        او ممكن تلاقى <a target='_blank' rel='noreferrer' href='https://goo.gl/z2Z4Do'>جامعات</a> بتوافق بإثبات الدراسة بالانجليزى
                                        <br/>
                                        - لو الدراسة بالالمانى
                                        <br/>
                                        الجامعات بتطلب الشهادات دى
                                        <br/>
                                        - Dsh 1 or 2 or 3
                                        <br/>
                                        - TestDAF  3 or 4 or 5
                                        <br/>
                                        <a target='_blank' rel='noreferrer' href='https://youtu.be/j-NLzg4rYNo'>TestDaF/ DSH الفرق بين</a>
                                        <br/>
                                        - Goethe C2 or C1 or B2
                                        <br/>
                                        - ÖSD C2 or C1 or B2
                                        <br/>
                                        - Telc Hochschule C1 or B2
                                        <br/>
                                        هتلاقى كل جامعة بتطلب مستوى معين من كل شهادة
                                        <br/>
                                        ممكن تلاقى جامعة بتطلب الخمسة وانت تحقق واحدة منهم
                                        <br/>
                                        ممكن تلاقى جامعة طالبة اتنين وتحقق واحدة منهم
                                        <br/>
                                        = لو الدراسة بالانجليزى فقط و الجامعة مش عايزة المانى فـ انت مش هتحتاج شهادة المانى و انت بتقدم على الجامعة او وانت بتقدم السفارة برضه و العكس صحيح
                                        <br/>
                                        = فيه جامعات بيبقا الدراسة فيها بالانجليزى بس بتطلب منك يكون معاك مستوى معين فى الالمانى 
                                        <br/>
                                        = فيه جامعات ممكن تلاقى فيها برامج الدراسة فيها المانى و انجليزى فى نفس التخصص و بيطلبوا منك شهادتين للدراسة
                                        <br/>
                                        __________
                                        <br/>
                                        * القبول المشروط
                                        <br/>
                                        Conditional Admission 
                                        <br/>
                                        القبول المشروط هو خطاب قبول من الجامعه بتعطيه للطلاب الاجانب اللذين هم من خارج الاتحاد الاوروبى و هذا القبول يضمن للطالب حجز المقعد والقبول النهائي بالجامعه متى ماحقق الطالب الشروط الموضحه في خطاب القبول المشروط وعادة تكون (تم قبولك بشرط ان تحضر لنا مايثبت حصولك على شهادة اللغة المطلوبه )
                                        <br/>
                                        دة فيما معناه ان انت هتاخد قبول من الجامعة وانت لسا مكملتش اللغة المطلوبة للقبول النهائى و هتسافر تكمل اللغة فى المانيا و بمجرد ما هتخلص اللغة و تحقق شرط اللغة فهتبدأ دراسة
                                        - صلاحية القبول المشروط ؟
                                        <br/>
                                        الجامعات اللى بتقدم القبول المشروط بتبقا كاتبة في القبول مدة صلاحية القبول و اللى غالبا فى معظم الجامعات بتبقا سنة و قليل لما بتلاقى اقل او اكتر من سنة 
                                        مثلا لو جامعة كاتبة فى خطاب القبول ان القبول دة صلاحيته من 10/2018 ل 10/2019
                                        <br/>
                                        فلازم فى المدة دى تكون مخلص اللغة ومحقق شرط اللغة المطلوب للقبول النهائى يا اما القبول دة بيبقا ملغى 
                                        <br/>
                                        = القبول المشروط لكل الدرجات ... سنة تحضيرية و بكالوريوس و ماستر
                                        <br/>
                                        = مش كل الجامعات بتعطى قبول مشروط
                                        <br/>
                                        والقبول المشروط بيختلف من جامعة لجامعة
                                        <br/>
                                        مثلا ممكن تلاقى جامعة بتوافق بالقبول المشروط بدون ما يكون معاك اى مستوى لغة وجامعة تانية ممكن تطلب مستوى لغة معين مثلا
                                        <br/>
                                        a1 / a2 / b1 / b2
                                        <br/>
                                        يعنى كل جامعة ليها شرط للغة حتى للقبول المشروط
                                        <br/>
                                        - الرد من الجامعة بالقبول او بالرفض فبيبقا على حسب نظام كل جامعة و كل جامعة بتبقا كاتبة على موقعها االفترة المتوقعة
                                        <br/>
                                        - الرد من الجامعة بالقبول او الرفض ممكن يبقا بالبريد او على الايميل .. و غالبا اللى بالبريد مبيوصلش لان هنا فى مصر بيضيع فبتطلب من الجامعة يبعتوا القبول على الايميل ..  
                                        <br/>
                                        = طب هتكمل اللغة فين ؟
                                        <br/>
                                        فيه بعض الجامعات فيها معهد لغة وبتحجز في معهد اللغة التابع للجامعة
                                        <br/>
                                        و فيه جامعات مفيهاش معهد لغة وتضطر تحجز فى معهد خاص فى نفس المدينة او خارجها
                                        <br/>
                                        ودي بتعرفها لما بتراسل الجامعات
                                        <br/>
                                        - C1 بتحجز في المانيا باقى المستويات اللى نقصاك مثلا لو الجامعة اللى اخدت منها قبول وبتطلب لبدأ الدراسة مستوى
                                        <br/>
                                        و انت معاك وليكن مستوى B1 فقط
                                        <br/>
                                        فبتحجز باقى المستويات اللى هما
                                        <br/>
                                        B2 / C1
                                        <br/>
                                        و لازم الكورس يكون مكثف مثلا 20 ساعة فى الاسبوع
                                        <br/>
                                        - سعر المستوى الواحد فقط فى المانيا يتراوح ما بين 400: 500 يورو و فيه اعلى من كدة 
                                        <br/>
                                        - فيه معاهد بتعمل تحديد مستوى حتى لو معاك شهادة جوتة و فيه معاهد لاء
                                        <br/>
                                        - فيه معاهد اثناء الحجز وانت لسا في مصر تشترط دفع ثمن المستوى كامل و فيه معاهد بتطلب مبلغ بسيط للحجز و معاهد أخرى بتحجز فيها ببلاش و تدفع ثمن المستوى لما توصل المانيا
                                        <br/>
                                        "كل معهد وله نظام" 
                                        <br/>
                                        - لو حجزت فى معهد و دفعت ثمن الكورس ومسافرتش فممكن ترجع الفلوس كلها و ممكن يتخصم منك مبلغ
                                        <br/>
                                        "كل معهد وله نظام"
                                        <br/>
                                        - لو الجامعة مفيهاش معهد لغة وسألتها عن المعاهد في المدينة فهترشحلك معاهد للغة في المدينة 
                                        <br/>
                                        - لو اخدت مثلا قبول مشروط من جامعة وانت مش معاك شهادة لغة فمش لازم تدخل السفارة وانت بدون لغة. لاء عادى ممكن تدرس لغة في مصر وتمتحن وتدخل السفارة بشهادة المستوى اللى امتحنته و تسافر تكمل الباقى
                                        <br/>
                                        - القبول المشروط في بعض الجامعات بيبقا له مواعيد للتقديم مختلفة عن القبول النهائي 
                                        <br/>
                                        = أنواع القبول المشروط ؟
                                        <br/>
                                        يوجد نوعين من القبول المشروط و ياريت الكل يركز في النقطة دى
                                        <br/>
                                        أولا – فيه جامعات بتعطى قبول مشروط و بمجرد ما الطالب هيخلص اللغة و يحقق شهادة اللغة المطلوبة فهيبدأ دراسة في الجامعة من غير ما يقدم من تانى
                                        <br/>
                                        ثانيا - فيه بعض الجامعات مفيهاش قبول مشروط ولكن بتعطى قبول بيعادل القبول المشروط و القبول دة اسمه قبول لغة و القبول دة بيعادل القبول المشروط بالنسبة للسفارة 
                                        <br/>
                                        قبول اللغة دة بيبقا مكتوب فيه ان انت قدمت على الجامعة و تخصص كذة و اخدت منها قبول ان انت هتخلص اللغة في معهد الجامعة و بعد كدة هتقدم على التخصص من تانى" يعنى مقعدك مش مضمون"
                                        <br/>
                                        قبول اللغة دة للتخصصات الطبية " يعنى اللى هيقدم على تخصص طبى فيراسل الجامعات اللى فيها معهد لغة"
                                        <br/>
                                        و أيضا فيه بعض الجامعات للتخصصات الغير طبية بتقدم القبول دة زى فرانكفورت و بوخوم
                                        <br/>
                                        القبول النهائي و القبول المشروط و قبول اللغة من الجامعة" فيزا دراسة"
                                        <br/>
                                        لو اخدت قبول مشروط من جامعة بداية الدراسة فيها شتوى فقط و خلصت اللغة قبل بداية الترم الصيفى فمش هتعرف تبدأ دراسة غير في الشتوى و العكس برضه لو بداية الدراسة في الصيفى فقط
                                        <br/>
                                        اما لو الجامعة مفتوح فيها التقديم في الترمين فممكن تبدأ الدراسة في اى ترم حتى لو كنت مقدم على شتوى
                                        <br/>
                                        القبول النهائي او القبول المشروط ممكن يتجدد و ممكن لاء . على حسب نظام الجامعة اللى هتقدم عليها  
                                        <br/>
                                        الجامعات اللى بتعطى قبول مشروط معظمها معروف للكل " مش هقدر اقول اسمها " و نصيحة منى بلاش تروح على جامعة الكل بياخد منها قبول لان كدة الضغط عليها بيزيد و مكتب الاجانب هتلاقى عليه ضغط
                                        <br/>
                                        القبول المشروط السفارة مش بترفضه
                                        <br/>
                                        ادخل السفارة و انت معاك على الاقل شهادة
                                        <br/>
                                        A2 / B1
                                        <br/>
                                        كل ما كان معاك مستوى لغة عالى فكان افضل و نسبة قبولك للفيزا هتبقا كويسة
                                        <br/>
                                        مفيش قبول مشروط ان انت تسافر تدرس اللغة الانجليزية فى المانيا
                                        <br/>
                                        القبول المشروط للدراسة باللغة الالمانية فقط
                                        <br/>
                                        _______________________________
                                        <br/>
                                        ثالثا
                                        <br/>
                                        ازاى تقدم على الجامعة ؟
                                        <br/>
                                        أولا - لازم تعرف الجامعة اللى هتقدم عليها بتفتح التقديم امتى
                                        <br/>
                                        ثانيا - لو هتقدم على قبول مشروط او نهائي فلازم تعرف ايه مستوى اللغة المطلوب اللى الجامعة بتطلبه
                                        <br/>
                                        ثالثا - لازم تعرف كل جامعة محتاجة أوراق ايه اثناء التقديم وهل محتاجة الورق متوثق والا لاء و عايزين فلوس والا لاء لفحص الورق
                                        <br/>
                                        رابعا - لازم تعرف الجامعة اللى هتقدم عليها" طريقة التقديم عليها ازاى"
                                        <br/>
                                        لان فيه 3 طرق للتقديم 
                                        <br/>
                                        - هل عن طريق اليونى اسيست و دة <a target='_blank' rel='noreferrer' href='https://goo.gl/JZxRQZ'>شرح</a> كافى عنه
                                        <br/>
                                        - هل مباشر " ان الواحد يملى ابلكيشن الجامعة ويطبعه و يرسل الورق بالبوسطة على عنوان الجامعة"
                                        <br/>
                                        - هل اونلاين فقط " الواحد يملى ابلكيشن من على موقع الجامعة و يعمله ابلود ولا يحتاج ان هو يرسل الورق بالبوسطة"
                                        <br/>
                                        _________
                                        <br/>
                                        ازاى ترسل الورق بالبوسطة للجامعة ؟
                                        <br/>
                                        هنا في مصر فيه اكتر من شركة شحن الواحد يقدر يبعت من خلالها الورق للجامعة لو التقديم على الجامعة بالبوسطة او باليونى اسيست
                                        <br/>
                                        - DHL
                                        <br/>
                                        أسرعهم وأغلاهم
                                        <br/>
                                        - TNT
                                        <br/>
                                        عاملين عرض للطلبة كويس والمفضلة بالنسبالى
                                        <br/>
                                        - Middle East
                                        <br/>
                                        - البريد المصرى السريع / العادى
                                        <br/>
                                        اكتر أربع شركات معروفين
                                        <br/>
                                        تقدر تتصل بخدمة العملاء وتعرف منهم على الأسعار وعلى أقرب فرع بالنسبالك و كمان المدة اللى هيوصل فيها الورق
                                        <br/>
                                        __________________________
                                        <br/>
                                        حاجة أخيرة : كل حاجة في البوست دة مش هتقدر تعرفها غير لما تراسل الجامعة و تتأكد منهم من كل معلومة عايزها
                                        <br/>
                                        احنا كطلاب مصريين " فبيطلق علينا الطلاب الأجانب او طلاب من خارج الاتحاد الاوروبى" 
                                        <br/>
                                        لان هتلاقى على موقع الجامعة كاتبين 
                                        <br/>
                                        مثلا طلاب الاتحاد الاوروبى تقديمهم فترة كذة و الطلاب الأجانب فترة كذة او ممكن تلاقى الاتنين زى بعض " وجب التنبيه عشان متتخضش لما تشوف كلمة الطلاب الأجانب"
                                        <br/>
                                        <a target='_blank' rel='noreferrer' href='https://goo.gl/y1FDvg'>فيديو</a> لطريقة استخدام موقع الدااد و البحث عن الجامعات ولغة الدراسة وطريقة مراسلة الجامعة
                                        <br/>
                                        <a target='_blank' rel='noreferrer' href='https://goo.gl/ofBRh8'>البوست المثبت - الكنز -</a>
                                    </h3>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </main>
            </article>
        );
    }
}

export default Apply;
