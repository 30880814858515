import React from "react";
import one from './one.jpeg';
import './Refund.css';

class Refund extends React.Component {
    render() {
        return (
            <article className="article br3 ba b--black-10 w-100 w-50-m w-50-l mw7 shadow-5 center">
                <main className="pa4 black-80">
                    <div className="measure">
                        <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
                            <div className='legend center'>
                                <legend className="f1 fw6 ph0 mh0">
                                    غلق الحساب
                                <br/>
                                    و استرجاع الفلوس
                                </legend>
                            </div>
                            <hr/>

                            <div className="ui form ma0">
                                <div className="fields">
                                    <div class="row">
                                        <div class="column">
                                            <img alt='' width='600px' height='450px' src={one} />
                                        </div>
                                    </div>

                                    <hr/>
                                    <h3>
                                        ناس كتير الفترة اللى فاتت سألت ازاى تقفل الحساب البنكى و ترجع الفلوس بعد ما حولتها لان الفيزا اترفضت او حصل ظرف ما و مقدرتش ان هى تدخل السفارة و تكمل .
                                        <br/>
                                        = فيرجى اتباع الخطوات التالية
                                        <br/>
                                        = عند رفض طلب منح التأشيرة
                                        <br/>
                                        يرجي في هذه الحالة ارسال قرار السفارة عدم منح التأشيرة مرفقاً به طلب إغلاق الحساب المجمد ويمكنكم تحميل هذا الطلب من على صفحة الإنترنت الخاصة بالمؤسسة المصرفية. ولا يتطلب هذا الأمر التصديق على توقيعاتكم لدى السفارة الألمانية.
                                        <br/>
                                        ........................................................
                                        <br/>
                                        = سحب طلب منح التأشيرة
                                        <br/>
                                        يرجي في هذه الحالة الذهاب إلى السفارة في المواعيد المحددة لهذا الغرض وهي من الأحد إلى الخميس من الساعة الثامنة صباحاً وحتي الساعة العاشرة صباحاً وإحضار جواز السفر الخاص بكم و تحصلون من السفارة بعد ذلك على خطاب تقومون بتقديمه للبنك حيث تفيد السفارة بموجبه بقيامكم بسحب طلب الحصول على التأشيرة الخاص بكم. رسوم هذه الخدمة ٢٥ يورو. 
                                        <br/>
                                        جدير بالذكر أن فحص الطلب يستغرق في العادة يوم عمل واحد.
                                        <br/>
                                        لاستلام المستندات الخاصة بكم يرجى الذهاب في يوم العمل التالي إلى السفارة في المواعيد المخصصة لذلك والدخول من بوابة قسم الجوازات والتصدتقات
                                        <br/>
                                        بعد ذلك يمكنكم ارسال خطاب السفارة وطلب اغلاق الحساب المجمد إلى البنك في ألمانيا.
                                        <br/>
                                        ويمكنكم تحميل هذا الطلب من على صفحة الإنترنت الخاصة بالمؤسسة المصرفية. ولا يتطلب الأمر التصديق على توقيعاتكم لدى السفارة الألمانية.
                                        <br/>
                                        .......................................................
                                        <br/>
                                        = انتهاء مدة التأشيرة أو تصرتح الإقامة
                                        <br/>
                                        و يرجي الحضور إلى السفارة وتقديم المستندات التي تفيد انتهاء مدة التأشيرة أو مدة سريان تصريح الإقامة من أصل وصورة وذلك في المواعيد المحددة لذلك من الأحد إلى الخميس من الساعة الثامنة وحتى الساعة العاشرة صباحاً و الدخول من بوابة قسم الجوازات والتصدتقات.
                                        <br/>
                                        بناء على هذا ستحصلون من السفارة على خطاب يقدم إلى البنك تفيد بموجبه السفارة بانتهاء مدة التأشيرة أو مدة تصريح الإقامة الخاص بكم. رسوم هذه الخدمة ٢٥ يورو.
                                        <br/>
                                        طاب مساؤُكم ..
                                    </h3>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </main>
            </article>
        );
    }
}

export default Refund;
