import React from "react";
import './UniTyp.css';

class UniTyp extends React.Component {
    render() {
        return (
            <article className="article br3 ba b--black-10 w-100 w-50-m w-50-l mw7 shadow-5 center">
                <main className="pa4 black-80">
                    <div className="measure">
                        <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
                            <div className='legend center'>
                                <legend className="f1 fw6 ph0 mh0">ما هو الفرق بين انواع الجامعات الألمانية النظرية و العملية ؟</legend>
                            </div>
                            <hr/>

                            <div className="ui form ma0">
                                <div className="fields">
                                    <h3>
                                        السلام عليكم
                                        <br/>
                                        نظم التعليم العالي فى ألمانيا
                                        <br/>
                                        ألأول نعرف شوية مصطلحات
                                        <br/>
                                        Hochschule (HS)
                                        <br/>
                                        ........................................
                                        <br/>
                                        تعني التعليم الجامعي او التعليم العالي فهي كلمه عامه تعبر عن التعلم بعد مرحلة التعليم الأساسي
                                        <br/>
                                        Hochschule يطلق مصطلح
                                        <br/>
                                        .على الجامعه (تطبيقية او بحثية)
                                        <br/>
                                        دي مش نوع من الجامعات
                                        <br/>
                                        هناك انواع كثيرة من الجامعات
                                        <br/>
                                        = كمثال و ليس للحصر
                                        <br/>
                                        ........................................
                                        <br/>
                                        <div className='lft'>
                                            Hochschulen(HS)
                                            <br/>
                                            -Wissenschaftliche Hochschule
                                            <br/>
                                            1-Universität (Uni)
                                            <br/>
                                            2-Technische Universität (TU)
                                            <br/>
                                            -Hochschule für angewandte Wissenschaften (HAW)
                                            <br/>
                                            3-Fachhochschule (FH or FHs)
                                            <br/>
                                            4-Technische Hochschule (TH or THs)
                                        </div>
                                        - رقم 1 و 2 الدراسة بتكون من الناحية النظرية / العلمية / البحثية مثل:
                                        <br/>
                                        ........................................
                                        <br/>
                                        <div className='lft'>
                                            tu-berlin(Technical University of Berlin).
                                            <br/>
                                            tu-münchen(Technische Universität München).
                                            <br/>
                                            Universität Heidelberg(Heidelberg University).
                                        </div>
                                        - رقم 3 و 4 بيعبروا عن نفس الشيء يكاد يكون متماثل الإختلاف ممكن في عدد ونوع التخصصات الموجودة فى الجامعات دي والدراسة بتكون من الناحية العلمية / العملية يعني بتدرب ترم كامل فى شركة ومش كل النوع ده من الجامعات بتوفر تدريب مثل:
                                        <br/>
                                        ........................................
                                        <br/>
                                        <div className='lft'>
                                            th-Koeln(Cologne University of Applied Sciences).
                                            <br/>
                                            fh-Aachen(Aachen University of Applied Sciences).
                                            <br/>
                                            hs-München(Munich University of Applied Sciences).
                                            <br/>
                                            FH / TH / (HAW).
                                        </div>
                                        ........................................
                                        <br/>
                                        - تركز  تلك الجامعات  على الطرق العملية للتعلم بنسب تتفاوت من جامعة لأخري ومن تخصص لأخر يعني 70-80% نظري والباقي عملي ممكن النسب دي تزيد او تقل مش قانون
                                        <br/>
                                        هذه الجامعات لديها علاقة قوية جدا مع المصانع والشركات (سوق العمل بشكل عام)
                                        <br/>
                                        إذا كنت ستقوم بالعمل بعد التخرج مباشرة
                                        <br/>
                                        تناسب أهدافك FH
                                        <br/>
                                        **لا تقدم الدكتوراه. لا يمكنك التقدم للحصول على شهادة الدكتوراه بعد حصولك على درجة الماجستير في نفس الكلية. ومع ذلك ، يمكنك الحصول على شهادة الدكتوراه في "الجامعة" رقم 1 او 2 بعد إنهاء الماجستير الخاص بك في FH او TH
                                        <br/>
                                        بوضوح ، معاك الحق فى إختيار  ، إذا كنت ترغب في العمل بعد الماجستير أو متابعة شهادة الدكتوراه
                                        <br/>
                                        ..........................................................
                                        M.sc عشان تدرس دكتوراة بقى محتاج
                                        <br/>
                                        M.Eng علي حد علمي مش هينفع
                                        <br/>
                                        تحديث
                                        <br/>
                                        *** M.Eng النقطة دي تحديدا بتختلف من جامعة لأخري ومن تخصص لأخر بس ممكن تعمل دكتوراة بعد
                                        <br/>
                                        ..........................................................
                                        <br/>
                                        B.Sc vs B.Eng and M.Sc vs M.Eng
                                        <br/>
                                        المناهج الدراسية لدرجة بكالوريوس العلوم هي نظرية أكثر. وهذا يعني أن البرنامج الدراسي لدرجة البكالوريوس في العلوم مكرس في الغالب للبحث والمبادئ النظرية ، استنادا إلى التجارب السابقة. ويمكن أن تشمل دورات عملية (وعلى الأرجح تشمل) ، ولكن الهدف من الدورات العملية هو إثبات المبادئ النظرية. 
                                        <br/>
                                        درجة البكالوريوس في الهندسة ، وأكثر تركيزا على الأنشطة العملية. بطبيعة الحال ، تعتبر المقررات النظرية جزءًا هامًا للغاية من المناهج الدراسية ، ولكنها تهدف إلى شرح الممارسة.
                                        <br/>
                                        ..........................................................
                                        <br/>
                                        computer science علي سبيل المثال اللى بيدرس . فى 1 او 2 او 3 او 4. بيتخرج معاه B.sc بس فى 3 و 4 بيديك ميزه فى سوق العمل انك شخص مدرب على اللى درسته. وهو ده هدف وجودهم أصلا.
                                        <br/>
                                        لا يوجد فرق تقريبا فى الدراسة العلمية بين التخصص ده فى اى من الجامعات الا فى مواد بسيطه جدا وبتكون مواد إضافية او ليها اسم مختلف بس المحتوي واحد إنت فى النهاية حاصل على B.sc. 
                                        <br/>
                                        ..........................................................
                                        <br/>
                                        بالنسبة للعمل والمرتبات الشهادات ملهاش عامل كبير
                                        <br/>
                                        خبرتك العملية في مجال دراستك هي اللي هتحدد مرتبك او منصبك مش الشهادة كل دي أرزاق ومؤهلات خاصة بيك.
                                        <br/>
                                        ..........................................................
                                        <br/>
                                        كل الشهادات دى معترف بيها فى أوربا وكل دول العالم وبالطبع في مصر
                                        <br/>
                                        اتعلم انت بس ملكش دعوه المهم تتعلم اللي بتحبه حتى لو فى بيتك تحت البطانيه
                                        <br/>
                                        *** متفكرش فى أمور روتينيه مستقبليه كتير عشان هتتعب ومش هتتحرك من مكانك ***
                                        <br/>
                                        للبحث عن الجامعات سواء علمية او تطبيقيه
                                        <br/>
                                        <a target='_blank' rel='noreferrer' href='https://www.studis-online.de/Hochschulen/'>studis-online.de/Hochschulen</a>
                                        <br/>
                                        لو فية معلومة ناقصه او خطأ عندي ياريت الناس متبخلش بالمعلومة المفيدة بناء على تجارب
                                    </h3>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </main>
            </article>
        );
    }
}

export default UniTyp;
