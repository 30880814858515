import React from "react";
import './More.css';

class More extends React.Component {
    render() {
        return (
            <article className="article br3 ba b--black-10 w-100 w-50-m w-50-l mw7 shadow-5 center">
                <main className="pa4 black-80">
                    <div className="measure">
                        <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
                            <div className='legend center'>
                                <legend className="f1 fw6 ph0 mh0">هل الحساب البنكى مطلوب كل سنة ؟</legend>
                            </div>
                            <hr/>

                            <div className="ui form ma0">
                                <div className="fields">
                                    <h3>
                                        البوست ده هتكلم في عن تجديد الأقامة للطالب لأن جه فيها أسئلة كتير
                                        <br/>
                                        هل بحتاج أحط نفس مبلغ الحساب البنكي كل سنة لتجديد الأقامة ؟
                                        <br/>
                                        تجديد الأقامة في ألمانيا مسألة بتخضع لسياسة كل مكتب أجانب و أداء كل موظف يعني مثلا
                                        <br/>
                                        دويسبورج بتطلب منك نص مبلغ الحساب علشان تجددلك سنه في حين أن مولهايم تبعد عنها 7 دقايق ممكن تديلك سنتين بألفين يورو و جنبهم أيسن يقال أنها بقت تطلب مبلغ الحساب البنكي كامل لتجديد الأقامة؛ في حين أن برلين ممكن تديك أقامة 3 سنين.
                                        <br/>
                                        جوا كل مكتب بيختلف موظف من التاني ممكن تكون أنت و صاحبك نفس الحالة بتجددوا في نفس اليوم عند أتنين موظفين واحد ياخد سنه و التاني سنتين طبقا للي شايفه الموظف و ده حاجه سيئة جدا لأنك كدا هتتحط تحت رحمة الموظف و لو عنصري طبعا هيحاول يضيق عليك.
                                        <br/>
                                        أفضل حالة لتجديد الأقامة هي ان يكون عندك خطاب ضمان مالي من حد جوا ألمانيا  ما بتحتاجش ساعتها يكون في حسابك أي حاجه مجرد بتروح توريهم الورقة و يجددولك الأقامة.
                                        <br/>
                                        هل الموظف بيطلب درجات الجامعة ؟
                                        <br/>
                                        غالبا لو بقالك سنتين أو أكتر مكتب الأجانب ممكن يطلب منك تجيبله درجاتك من الجامعه علشان يشوف مدى تقدمك في الدراسة هل بتمتحن و بتدرس ولا لا؛ لو لقاك ما بتدخلش أمتحانات و مافيش سبب مرضي ساعتها بيبدأ يضيق عليك يجددلك أقامتك 6 شهور في 6 شهور و يشرط عليك تجيله ناجح في عدد مواد معين بيكدرك بمعنى أصح علشان تنجز في دراستك.
                                        <br/>
                                        لو مابدرسش ممكن أترحل ؟
                                        <br/>
                                        الترحيل صعب ما شوفتش حالة قبل كدا ادامي لكنه وارد و من حقه لكنه بييجي بالتدريج؛ يعني من اللي سمعته كمثال بقالك مثلا 5 سنين و ما بتمتحنش ساعتها بيديك أقامة 6 شهور لو جيت مش ممتحن يديلك 3 مثلا بعدين 3 بيحسسك أنك في دايرة الخطر لو وصلت سنه و انت بردو ما بتتقدمش في دراستك ساعتها أعتقد ممكن توصل لمرحلة الترحيل
                                        <br/>
                                        نصيحة لو عاوز ما يحصلكش مشاكل في اقامتك ادخل امتحاناتك بانتظام و حاول تحافظ على شغل مستدام علشان يكون معاك اي مبلغ في حسابك يكفي لتجديد اقامتك اللي بتعرف هو كام من زمايلك في نفس المدينة.
                                        <br/>
                                        ______________________________________
                                        <br/>
                                        <a target='_blank' rel='noreferrer' href='https://www.facebook.com/groups/311111149267612/posts/555075124871212/'>بوست زياده</a>
                                    </h3>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </main>
            </article>
        );
    }
}

export default More;
