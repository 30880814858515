import React from "react";
import './AcceptTypes.css';

class AcceptTypes extends React.Component {
    render() {
        return (
            <article className="article br3 ba b--black-10 w-100 w-50-m w-50-l mw7 shadow-5 center">
                <main className="pa4 black-80">
                    <div className="measure">
                        <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
                            <div className='legend center'>
                                <legend className="f1 fw6 ph0 mh0">ما هو القبول المقيد و المفتوح في ألمانيا و ما نسبة قبولى في الجامعة</legend>
                            </div>
                            <hr/>

                            <div className="ui form ma0">
                                <div className="fields">
                                    <h3>
                                        الفترة الاخيرة اكتر من واحد سأل عايز اجيب قبول "ماستر/ بكالوريوس" من جامعة قبولها سهل
                                        <br/>
                                        فكان الرد ان انت تبعد عن الجامعات اللى عليها ضغط و فعلا مفيش حد يقدر يقول غير كدة 
                                        <br/>
                                        فيه حاجة ممكن ناس كتير متعرفهاش و هى ان القبول فى التخصص اللى انت عايزه بيكون
                                        <br/>
                                        "مقيد او غير مقيد"
                                        <br/>
                                        و هشرح الفرق بين الاتنين فى البوست دة
                                        <br/>
                                        ______________________________________
                                        <br/>
                                        أولا القبول المقيد
                                        <br/>
                                        دة فيما معناه ان هما بيختاروا أعلى طلاب مقدمة وفقا للعدد المحدد
                                        <br/>
                                        مثلا لو عايزين 50 مقعد
                                        <br/>
                                        و 500  طالب مقدم 
                                        <br/>
                                        فبيختاروا اعلى 50  طالب من المتقدمين و بيبقا فيه تنافس
                                        <br/>
                                        فأصحاب التقديرات القليلة يفضل ان هما ميقدموش على النوع دة لان فرصتهم هتبقا قليل
                                        <br/>
                                        ______________________________________
                                        <br/>
                                        ثانيا القبول المفتوح و الغير مقيد
                                        <br/>
                                        دة لو انت تقديرك قليل فالأفضل تقدم على جامعات بيبقا القبول فيها فى القسم 
                                        <br/>
                                        مفتوح وعدم مقيد .. و دة معناه ان هما مبيحددوش تقدير معين بس مجرد ما تكون محقق الشروط فبتاخد قبول 
                                        <br/>
                                        يعنى مثلا لو عايز تقدم على بكالوريوس فالشروط ان انت تكون مخلص 
                                        <br/>
                                        ثانوية فوق ال60% + مخلص سنة جامعية 
                                        <br/>
                                        اما الماستر فـ انت تكون مخلص البكالوريوس ومبيبقوش محددين التقدير من كام
                                        <br/>
                                        بس فيه بعض الجامعات بتبقا محددة تقدير معين بس اللى محققه بياخد القبول و مبيبقاش فيه تنافس والكلام دة
                                        <br/>
                                        .......................
                                        <br/>
                                        طب تعرف ازاى ان البرنامج دة مقيد او غير مقيد ؟
                                        <br/>
                                        = كل اللى عليك تدخل على البرنامج اللى انت عايز تقدم عليه ع موقع الجامعة  و هتلاقى مكتوب مقيد او غير مقيد 
                                        <br/>
                                        وهتلاقى كلمة بتدل ان القبول فى التخصص دة ايه
                                        <br/>
                                        مثلا لو مقيد و محدود فهتلاقى مكتوب كلمة من الكلمات دى
                                        <br/>
                                        - Numerus clausus ( NC )
                                        <br/>
                                        - zulassungsbeschränkte 
                                        <br/>
                                        - selection by the HEI
                                        <br/>
                                        - Restricted admission
                                        <br/>
                                        .......................
                                        <br/>
                                        اما لو مش مقيد و مفتوح  فهتلاقى مكتوب كلمة من الكلمات دى
                                        <br/>
                                        - No NC
                                        <br/>
                                        - free
                                        <br/>
                                        - open admission
                                        <br/>
                                        - zulassungsfreien
                                        <br/>
                                        - UnRestricted admission
                                        <br/>
                                        .......................
                                        <br/>
                                        طب لو مش مكتوب كلمة من الكلمات دى ع الموقع و مش موضح ايه نوع القبول فى التخصص دة
                                        <br/>
                                        فكل اللى عليك انك تبعت للقسم او الانترناشونال اوفيس فى الجامعة 
                                        <br/>
                                        ايه نوع القبول فى التخصص وتقولهم اسم التخصص و اسهل كلمة
                                        <br/>
                                        NC or No NC 
                                        <br/>
                                        و هما هيردوا عليك
                                        <br/>
                                        .......................
                                        <br/>
                                        كل اللى عليك تعمله انك تدخل على موقع الدااد و تشوف التخصص اللى انت عايز تدرسه و تشوف الجامعات و تدخل على كل جامعة و تشوف تخصصك نوع القبول فيه ايه و القبول فى التخصص اللى انت عايزه مش فى كل الجامعات زى بعض 
                                        <br/>
                                        ممكن تلاقى فى جامعة مقيد و جامعة تانية غير مقيد
                                        <br/>
                                        المفروض تتعب شوية و تبحث انت بنفسك
                                        <br/>
                                        .......................
                                        <br/>
                                        راسلت جامعة قبل كدة و سألتها و قالت ان القبول مش محدود بس لو عدد المتقدمين زاد عن الحد اللى احنا عايزينه فممكن نختار العدد اللى احنا محددينة و أكيد هيختاروا الأعلى
                                        <br/>
                                        ______________________________________
                                        <br/>
                                        القبول المقيد/الغير مقيد حاجة
                                        <br/>
                                        و القبول المشروط حاجة تانية خالص
                                    </h3>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </main>
            </article>
        );
    }
}

export default AcceptTypes;
