import React from "react";
import './BasicDOCS.css';

class BasicDOCS extends React.Component {
    render() {
        return (
            <article className="article br3 ba b--black-10 w-100 w-50-m w-50-l mw7 shadow-5 center">
                <main className="pa4 black-80">
                    <div className="measure">
                        <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
                            <div className='legend center'>
                                <legend className="f1 fw6 ph0 mh0">الأوراق الأساسية للدراسة و توثيق الأوراق من السفارة الألمانية</legend>
                            </div>
                            <hr/>

                            <div className="ui form ma0">
                                <div className="fields">
                                    <h3>
                                        السلام عليكم
                                        <br/>
                                        النهاردة هتكلم عن توثيق الشهادات والأوراق من السفارة الألمانية في مصر
                                        <br/>
                                        هتكلم بالتفصيل عن الورق المطلوب لفيزا الدراسة و ازاى توثقه.
                                        <br/>
                                        تحديث 04.2021
                                        <br/>
                                        = المفروض اوثق ايه للدراسة
                                        <br/>
                                        دى بقا على حسب انت هتقدم على ايه
                                        <br/>
                                        لو انت هتقدم تحضيرية ف انت كدة المفروض هتوثق بيان او كشف درجات الثانوية فقط
                                        <br/>
                                        لو انت هتقدم بكالوريوس ف انت كدة هتوثق " ثانوية + بيان درجات او تقديرات مواد الجامعة للسنة او السنين اللى درستها هنا في مصر “
                                        <br/>
                                        لو انت هتقدم ماستر ف انت كدة هتوثق "ثانوية + بيان درجات او تقديرات مواد الجامعة + شهادة التخرج"
                                        <br/>
                                        ______________________________________
                                        <br/>
                                        طب انا خلاص عرفت ايه الشهادات المطلوبة .. طب اوثقها ازاى
                                        <br/>
                                        بص ي معلم خطوات التوثيق بتبقا من الجهة اللى صاردة منها الشهادة و بعدين خارجية وبعدين سفارة يعنى مفيش ورق هيتوثق سفارة غير لما يكون متوثق خارجية
                                        <br/>
                                        = ازاى تستخرج الشهادات دى وتوثقهم خارجية
                                        <br/>
                                        <br/>
                                        أولا - بيان درجات / كشف الدرجات الثانوية العامة
                                        <br/>
                                        اول حل انك هتروح الإدارة التعليمية اللى المدرسة بتاعتك تابعة ليها و بتستخرج بيان درجات الثانوية بالعربى و متوجهه لألمانيا و تقريبا بتستلمها بعدها بيومين تلاتة و دى بتختلف من مكان لمكان وبعد استلامها فلازم تختمها ختم المحافظة قبل ما توثقها خارجية او ممكن تسألهم في الإدارة هتختمها منين عشان تختم خارجية
                                        <br/>
                                        تانى حل انك ممكن تروح الإدارة العامة للإمتحانات في السيدة زينب فى القاهرة و تاخدها في نفس اليوم بس تستخرجها قبل الساعة ١٢.
                                        <br/>
                                        و دى ممكن تروحها حتى لو انت من خارج القاهرة و بتروح ع الخارجية علطول و مبتحتجش تختمها من اى مكان قبل الخارجية
                                        <br/>
                                        بعد ما تختار اى من الطريقين اللى فوق دول و توثق خارجية فبعد كدة لازم تروح تترجمها لألمانى من مترجم معتمد و بتروح ع السفارة علطول و مبترجعش للخارجية
                                        <br/>
                                        الثانوية بتستخرجها عربى فقط اما الترجمة الانجليزى او حتى الألماني مبقتش بتتوثق في السفارة و بقت مرفوضة
                                        <br/>
                                        مبتحتجش حاجة و انت بتطلع بيان درجات الثانوية غير اسمك و اسم المدرسة و البطاقة تقريبا
                                        <br/>
                                        طلاب الثانوية الأزهرية هيروحوا لقطاع المعاهد الأزهرية في شارع ثروت متفرع من شارع مصطفي النحاس في مدينة نصر بتحتاج رقم الجلوس. بالنسبه الأختام ققولهم يختموهالك كل الأختام عشان هتوثقها خارجية و بعد كدة تترجم من مترجم معتمد
                                        <br/>
                                        ______________________________________
                                        <br/>
                                        ثانيا- شهادات الجامعة
                                        <br/>
                                        بتروح للشئون عندك او الكنترول بتطلع الشهادة اللى انت عايزها
                                        <br/>
                                        مثلا لو انت معهد خاص فلازم تختمها ختم وزارة التعليم العالى قبل ما توثقها خارجية
                                        <br/>
                                        اما لو جامعة خاصة فلازم تسأل فى الشئون ايه الاختام المفروض تختمها لتوثيقها خارجية
                                        <br/>
                                        ولو جامعة حكومية فلازم تختمها من سكرتير الجامعة ولازم تعرفهم وانت بتطلعها انك عايز تختم خارجية ف ايه المطلوب عشان تختم خارجية
                                        <br/>
                                        لو الشهادة طلعتها بالعربى فلازم تروح لمترجم معتمد من السفارة تترجمها المانى بعد ختم الخارجية
                                        <br/>
                                        ولو هتطلعها انجليزى من الجامعة ف انت كدة مش هتحتاج تترجمها ل ألمانى بعد ختم الخارجية عكس الثانوية خالص
                                        <br/>
                                        ______________________________________
                                        <br/>
                                        = ترجمة الثانوية من مترجم معتمد فبتختلف ما بين مترجم و التانى فالأفضل تتصل بيهم و تعرف الأسعار
                                        <br/>
                                        = سعر بيان درجات/شهادة الجامعة بيختلف من جامعة لجامعة
                                        <br/>
                                        ______________________________________
                                        <br/>
                                        اهم نقطة في موضوع التوثيق من الخارجية انك لازم تقولهم ان الورق رايح السفارة الألمانية و عايز الختم كامل – ركزوا ي جماعة – لازم الختم يبقا كامل و مكتب الخارجية فاهم يعنى ايه الختم كامل و لو قالوا مبنعملش الختم كامل فبتروح مكتب تانى بيعمل الكلام دة
                                        <br/>
                                        ______________________________________
                                        <br/>
                                        = طب انا مش هسافر دراسة فهل هتبقا نفس الخطوات لاستخراج الورق؟
                                        <br/>
                                        اة هى هى مفيش اختلاف فى استخراج الورق
                                        <br/>
                                        ______________________________________
                                        <br/>
                                        كدة خلصت ي معلم توثيق شهادات الجامعة من الخارجية والثانوية و وثقتها خارجية و بعد كدة ترجمتها
                                        <br/>
                                        = فالخطوة الجاية هي توثيق السفارة
                                        <br/>
                                        اولا توثيق السفارة لازم بحجز ميعاد مسبق فى مركز
                                        TLS
                                        <br/>
                                        <a target='_blank' rel='noreferrer' href='https://www.facebook.com/groups/311111149267612/permalink/1376297142749002/'>و هنا هتلاقى الطريقة</a>
                                        <br/>
                                        بس يوجد خطوات للتوثيق ألا وهى ان انت بتوثق الاصل و بعد كدة بترجع توثق نسخ طبق الأصل لان عشان تقدم الورق للجامعات فمينفعش تقدم بالنسخة الأصلية لان النسخة الأصلية بتبقا معاك و محدش بياخدها منك فبتضطر انك بتطلع نسخ طبق الأصل و لينا كطلبة 5 نسخ طبق الأصل ببلاش و أي نسخة زيادة بتدفع عليها فلوس
                                        <br/>
                                        = طب ازاى توثق نسخ طبق الأصل
                                        <br/>
                                        كل اللى عليك أنك هتحجز ميعاد تانى بعد ما تستلم الأصل
                                        <br/>
                                        كإنك رايح توثق من جديد بس المرادى معاك
                                        <br/>
                                        النسخة الأصلية المتوثقة
                                        <br/>
                                        5 صور للنسخة الأصلية و متصورين من أي مكتبة وش و ضهر و بتبقا ابيض و اسود و الصور تكون واضحة و مفيش اى دبوس يتفك. ركزوا ي جدعان محدش يفك اى دبوس موجود فى الورق
                                        <br/>
                                        و بالصور المتوثقة " طبق الأصل " تقدر تقدم بيهم ع الجامعات
                                        <br/>
                                        و كدة بقا معاك الأصل المتوثق و محتفظ بيه و معاك خمس نسخ متوثقين تقدر تراسل بيهم الجامعات
                                        <br/>
                                        <a target='_blank' rel='noreferrer' href='https://www.facebook.com/groups/311111149267612/permalink/598442577201133/'>تعليمات جديدة لتوثيق الاصل و طبق الاصل</a>
                                        <br/>
                                        ........................................
                                        <br/>
                                        شهادات اللغة تقدر توثقها من السفارة
                                        <br/>
                                        "الإيلتس و الألماني"
                                        <br/>
                                        كل اللى عليك هتصور الاصل و هتروح بالأصل و الصور اثناء توثيق طبق الاصل و ليك 5 صور توثقهم ببلاش و ممكن يطلبوا اثبات ان الجامعة عايزة شهادة اللغة مثلا عشان نوثقها ببلاش و اوقات مبيطلبوش
                                        <br/>
                                        = الشهادات مبتتوثقش خارجية
                                        <br/>
                                        = "شهادات اللغة عادة الجامعات مبتطلبهاش متوثقة بس ممكن اليونى اسيست يطلبها متوثقة من السفارة "
                                        <br/>
                                        ........................................
                                        <br/>
                                        معلومات مهمة
                                        <br/>
                                        التوثيق في السفارة الألمانية في القاهرة ببلاش للدراسة و بفلوس لأى حاجة تانية
                                        <br/>
                                        التوثيق ببلاش للورق اللى الجامعة عايزاه مهما كانت الورق ايه .. لأى حد رايح سنة تحضيرية او بكالوريوس او ماستر " ليك النسخة الأصلية ببلاش + 5 نسخ طبق الاصل ببلاش و النسخة الزيادة بفلوس "
                                        <br/>
                                        التوثيق ببلاش لاى حد مهما كان عمره لو طالع دراسة يعنى مش 26 والا الكلام دة
                                        <br/>
                                        لا يوجد فرق بين بيان الدرجات او بيان التقديرات لان كل جامعة فيها نظام غير جامعة اخرى والاتنين زى بعض
                                        <br/>
                                        لو انت خلصت بكالوريوس و عايز تقدم على بكالوريوس من تانى فهتوثق الثانوية و بيان درجات الجامعة و شهادة التخرج
                                        <br/>
                                        المحتوى العلمى و اثبات القيد او خطاب التوصية او شهادة الخبرة لو عايز توثقهم لازم تجيب اثبات من الجامعة ان هى عايزاه عشان توثقه ببلاش او ممكن يوثقوه من غير اثبات بالحب
                                        <br/>
                                        المحتوى العلمى عشان يتوثق من السفارة فلازم " اول صفحة يبقا مكتوب فيها اسمك و رقمك فى الكلية و اسم الجامعة و الكلية " و تبقا مختومة من الجامعة و الخارجية و تعتبر ورقة رسمية.
                                        <br/>
                                        ورقة ال granding system
                                        <br/>
                                        و دى ورقة بيبقا فيها نظام التقديرات او الدرجات فى الجامعة اللى درست فيها لان معظم الجامعات بتطلبها خصوصا لو هتقدم على ماستر و الجامعة عايزة تحسب مجموعك و بيان التقديرات او الدرجات للمواد مفيش فيه توضيح الدرجات او التقديرات .. مثلا المقبول من 50 ل 65 و الجيد من 65 ل 75 و الخ .. و مش لازم تتوثق سفارة والا خارجية .. كفاية ختم الجامعة و الكلية او المعهد
                                        <br/>
                                        اثبات الدراسة بالانجليزى ... <a target='_blank' rel='noreferrer' href='https://www.facebook.com/groups/311111149267612/permalink/466992803679445/'>هنا</a> فيه كل المعلومات
                                        <br/>
                                        لو الجامعة عايزة شهادة الميلاد او حتى اى ورقة فعادى ممكن توثقها من السفارة ببلاش بس باثبات ان الجامعة عايزة كدة و توثقها
                                        <br/>
                                        الموتفيشن و السى فى مبيتوثقوش خارجية والا سفارة والله
                                        <br/>
                                        كل حاجة بتبقا مكتوبة على ضهر الورقة" يعنى لو وثقت 3 نسخ طبق الاصل فهيبقا مكتوب على ضهر الاصل ان انت وثقت 3 نسخ ولسا 2"
                                        <br/>
                                        لو انت مثلا معاك شهادة ثانوية/جامعة من دولة خارج مصر فلازم توثقها من السفارة الألمانية في البلد اللى انت اخدت منها الشهادة لان هنا في مصر هيرفضوا يوثقوا الأصل
                                        <br/>
                                        ______________________________________
                                        <br/>
                                        هل ختم الخارجية على الورق له مدة صلاحية ؟
                                        <br/>
                                        لو هتوثق فى نفس السنة اللى انت ختمت فيها ختم الخارجية ف ليك سنة
                                        <br/>
                                        مثلا لو ختمت فى يناير2017 من الخارجية ف تقدر توثق فى السفارة فى ديسمبر 2017
                                        <br/>
                                        اما لو بدأت سنة ميلادية جديدة فلازم ختم الخارجية ميعديش عليه 6 شهور ولازم ترجع تجدد ختم الخارجية فقط حتى لو الورق كان مترجم" يعنى مش هتطلع الورق من جديد"
                                        <br/>
                                        توثيق السفارة صلاحيته مدى الحياة للاصل و النسخ طبق الاصل
                                        <br/>
                                        ______________________________________
                                        <br/>
                                        قائمة بأسماء المترجمين المعتمدين من السفارة الألمانية في مصر
                                        <br/>
                                        <a target='_blank' rel='noreferrer' href='https://kairo.diplo.de/blob/1505052/f1abbd4868bdc97804dd2b39d9f2c163/anerkannte-uebersetzer--arab--data.pdf'>من عربى الي ألمانى / ألمانى الي عربى</a>
                                    </h3>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </main>
            </article>
        );
    }
}

export default BasicDOCS;
