import React from "react";
import './Aupair.css';

class Aupair extends React.Component {
    render() {
        return (
            <article className="article br3 ba b--black-10 w-100 w-50-m w-50-l mw7 shadow-5 center">
                <main className="pa4 black-80">
                    <div className="measure">
                        <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
                            <div className='legend center'>
                                <legend className="f1 fw6 ph0 mh0">Aupair</legend>
                            </div>
                            <hr/>

                            <div className="ui form ma0">
                                <div className="fields">
                                    <h3>
                                        تجربه لشاب عربي مصري ٢٤ سنه
                                        <br/>
                                        6/7/2018 - Aupair
                                        <br/>
                                        انا هقسم البوست لكذا نقطه هتكلم فيها بالترتيب كدا علشان يبقي الموضوع سهل وبدون تعقيدات 
                                        <br/>
                                        1 _ ازاي ادور ع عائله المانيه وازاي الاقي العائله الي تناسبني كشاب او كبنت 
                                        <br/>
                                        2 _ الاوراق المطلوبه وهساعدك جامد جدا تحضر ورقك الشخصي و الورق المطلوب من العائله بكل سهوله 
                                        <br/>
                                        3 _ انترفيو السفاره بالنسبه لل Au_Pair الي يعتبر مختلف تماما عن اي انترفيو 
                                        <br/>
                                        نبدا بسم الله الرحمن الرحيم 
                                        <br/>
                                        ______________________________________
                                        <br/>
                                        النقطه الاولي ازاي ادور ع عائله , في البدايه لازم تعرف احنا كعرب سمعتنا مش حلوه اووي وفي عائلات لما بتطلب اوبير بتشترط انه يكون من الاتحاد الاوربي لاكن في عائلات كتير جدا بالنسبه لهم عادي طلاما الشخص كويس وبديل في مصريين كتير سافروا اوبير واعرفهم شخصيا وكتير جدا 
                                        <br/>
                                        طب كشاب ازاي ادور ع عائله : اغلب العائلات بتدور ع اوبير بنت ,, ليه ! علشان تساعد في الطبخ ولو الاولاد صغيرين يهتموا بيهم كغيرات و بامبرز بجانب شغل البيت الي نوعا ما معروف ان الراجل مبيفهمش فيه بجانب سبب كمان ان العائله ممكن يكون عندها بنت صغيره او كبيره فمش حابين اوبير ولد او يكون الزوجه سنها صغير مثلا سنها لحد 38 سنه مثلا فيخاف زوجها عليها منك كشاب فعشان كدا بيختارو بنت ,,, طب انا كشاب اعمل ايه 
                                        <br/>
                                        بص في عائلات برضو مبيفرقش معاهم اذا كان الاوبير دا ولد او بنت حتي لو عندهم بنت حتي لو الزوجه صغيره بس دول مش كتير ,, انما العائلات الي عندهم اولاد بس دول اكتر عائلات بتحتاج اوبير ولد علشان تكون فاهم اولادهم وعارفين انكم هتبقوا شباب مع بعض وهتعرفوا تندمجوا مع بعض كويس لان تفكيركم هيكون متشابه ,, دي اكتر عائله انت كشاب هتناسبك ,, ويا سلام لو تدور ع العائله الي سن الزوج و الزوجه يكون كبير ,, وهي دي العائله الي انا روحت عليها فعلا عندهم اولاد و سنهم كبير ,,, 
                                        <br/>
                                        عايز اطمنك انك انت كشاب مع عائله المانيه ,, حرفيا مش هتعمل اي حاجه ,, المساعده ف اعمال البيت دي هتكون ابسط مما تتخيل ومش هتعمل ربع الي بتعمله البنت الاوبير ,, لانهم عارفين انت كشاب مش بتفهم ف اعمال البيت ,, انت جاي بس علشان تقعد مع الاولاد وتعلبوا مع بعض ,, يعني يائما وقتك بتذاكر يائما بتلعب كوره مع الاولاد او اي رياضه تانيه وتقدر تعتبر الرياضه دي مفيده ليك قبل ما تكون انت بتشتغل ,,, ودا فعلا حالي معاهم 
                                        <br/>
                                        انتي كبنت اوبير ,, ما شاء الله ,,, زي ما قولت اغلب العائلات بتطلب بنات ف فرصتك كبيره جدا 
                                        <br/>
                                        ,,,وعليه انت كشاب دور ع العائله الي فيها نفس المواصلات الي قولتلك عليها ,, وانتي كبنت زي ما قولت 
                                        <br/>
                                        بالنسبه للمواقع الي تدور عليها موقع اوبير ورلد و جروب ع الفيس بوك اوبير دويتشلاند ,, وفي مواقع كتير جدا بس حستها رخمه جدا ملهاش اي لزمه ,,, وهسبلكوا لينكاتهم تحت ,,
                                        <br/>
                                        اول ما تخش الجروب هتلاقي كذا حد عايز يعمل اوبير ومنزل بوست وصور لنفسه ,, انت هتعمل زيه هتنزل بوست بمعلوماتك الشخصيه انت مين ومنين وسنك وبتعرف تعمل ايه وبتحب الاطفال ,,,الخ العائله الي هتلاقيك مناسب هتكلمك او انت تشوف بوستات العائله نفسها لان العائلات بتنزل بوستات تدور ع اوبير زيها زيك والعائله الي تشوفها مناسبه ليك بناءا ع الي قولته فوق كلمها ,,ودا الي حصل معايا ان اجبت العائله من ع الجروب دا ,,شوفت بوستهم وكلمتهم وبعد نص ساعه بعتولي كل الورق المطلوب 
                                        <br/>
                                        ______________________________________
                                        <br/>
                                        تاني نقطه الاوراق المطلوبه ,, بعد ماتحجز ميعاد مع السفاره ,,وتختار اخرررررر نوع  الي هي فيزا عمل ,, خلي بالك مهم جدا مش هتحجز ميعاد دررراسه ,,, هتحجز فيييييزا عمل ,, اللهم بلغت علشان ف ناس حجز دراسه وقالولهم انتي ف النوع الغلط وحجزوا ميعاد تاني واستنوا شهرين كمان 
                                        <br/>
                                        1.Lebenslauf ال سي في طبعا كله عرفه وهتكتبه الماااااااااااني ,, وهنزلكوا ال سي في بتاعي تفهموا منه تعملوه ازاي 
                                        <br/>
                                        2.Motivationsschreiben الخطاب التحفيزي ودا اهم ما ف الموضوع  و بالالماني,, هتتكلم فيه عن نفسك و ليه عايز تتعلم اللغه ,, واشعنا االالماني ,, واشمعنا المانيا ,,, والسنه الاوبير هتعيد علييك بفايده ايه لما ترجع مصر ,, وانت ايه مميزاتك ,, ,ليه انت مناسب ,,, ليه العائله مناسبه ليك ,, 
                                        <br/>
                                        3.erweiterte Meldebescheinigung
                                        <br/>
                                        دا تسجيل محل سكن العائله ودا هما الي هيبعتهولك ,, بس تتاكد ان الورق مكتوب فيها نفس الكلمه لاي انا كاتبهالك دي لان العائله اتدني ورقه عليها اسم شبهه كدا مكسل اجيبه معلش بس دا مينفعش لان مش هيكون مكتوب فيه كل التفاصيل المطلوبه زي الورقه الي هتنكون بالعنوان دا لانهم  ف  السفاره قالولي هتعمل استكمال بالورقه دي لانها نقصاك والورقه التانيه لاي جبتها قالولي مش فيها كل المعلومات فاحنا عايزين الورقه دي ,, وبصراحه هو مشوار رخم فمتعملوش استكمال خلوها مره واحده 
                                        <br/>
                                        4.A1 Zertifikat
                                        <br/>
                                        شهاده A1  بتاعه معهد جوته ,, لو مستواك اعلي وجبت مستوي اعلي منه دا افضل وبيقوي موقفك انا كنت جايب 3 شهادات لغه A1  بتاعه ÖSD دي كانت معايا من بدري اوي وكنت جايب B1 من معهد جوته و b1 من DFA  حد يقولي وليه جايب بتاعه معهد دي  اف اي ,, اقولك عشان اكدلهم اني شخص مهتم باللغه كدا ,, دا بيفرق معاك كتير ,,
                                        <br/>
                                        5.Au-Pairvertrag
                                        <br/>
                                        دا العقد الي بينك وبين العائله ,, العائله بتملي البيانات بتاعتها زي عنوانهم وعنوانك واساميهم واسمك وعنوانهم وعنوانك و عدد الاطقال الي عندهم ومرتبك هيبقي كام والزوج و الزوجه بيشتغلوا ايه ,,الخ ,, والي هو لازم انت تكون حافظ المعلومات دي زي اسمك قبل ماتخش الانترفيو 
                                        <br/>
                                        6.اثبات القيد : اثبات قيد من الكليه لو تعرف تطلعه انجلش ع طول وتوثقه خارجيه تمام ,, مش هتعرف يبقي عربي وترجمه عند مترجم معتنمد و وثقه خارجيه زي منا عملت  ولو انت خريج يبقي شهاده التخرج وتطبق عليها نفس الكلام 
                                        <br/>
                                        7.باسبورك الشخصي الصفحات الي فيها المعلومات والصفحه الي في الامضاء 
                                        <br/>
                                        8.البطايق الشخصيه بتاعه العائله او باسقبوراتهم حاجه من الاتنين ,, للزوج او الزوجه 
                                        <br/>
                                        اخيرا ودي مش من الورق المطلوب ولا حاجه بس مهمه جاد ,, موقع udemy بيعمل كورسات فري لكورس الاسعافات الاوليه ,, ومش شرط تسمعه اصلا ممكن تخش تعلم ع كل المحاضرات انك سمعتها وتخش تاخد الشهاده ع طول ف اقل من دقيقه وكمان كورسات معامله اطفال والاسره كل دا ببلاش خدهمم واطبع الشهاده وحطها ف ال cv  بتاعك هتفرق معاك جامد جدا جدا جدا ودا الي انا عملته 
                                        <br/>
                                        اخيرا // شايف الورق دا كله هتعمله كله نسختين مش نسخه واحده 
                                        <br/>
                                        ______________________________________
                                        <br/>
                                        النقطه الاخيره : انترفيو السفاره :: انا هقول الي حصل معايا بجانب الي انت المفروض متقعش فيه زي انا كدا
                                        <br/>
                                        انا جيت ف ميعادي كل الورق معايا منه نسختين ومرتبه ع قد ما قدرات ,, دخلت ع الشباك قالتلي انت طالع ايه قولتلها اوبير ,, رتبلتي الورق وكنت واخد معايا اكتر من 20 شهاده اون لاين بالكورسات الي ختها و كورس الاسعافات الاوليه بالهلال الاحمر المصري كمان بالانجليزي رتبلتي الورق ودخلت ع طول مكنش فيه غيري انا و واحد و المكان كان فاضي فشخ ,, انا مكنش عندي اي رهبه خالص من المكان لان المكان عامل زي البنك اصلا كراسي وبتستني رقمك يتنده عليه ,, زي شغل البنوك ,, وانا شغال ف بنك فحسيت ان المكان مش غريب عني ومكنش فيه اي رهبه ,, والثقه مطلوبه فشخ 
                                        <br/>
                                        ,,, نده عليا الشاب ابو نضاره ,, انا طبعا مرضتش اقراء التجارب المحبطه الي اتكلمت عن الواد دا ,, لاني مش مقتنع ان حد ممكن يوترني اصلا ,, انا ورقي تمام جدا وعارف اي نقط ضعفي و محضرلها اجابات وخلاص ,, فانا اقف قدام التخين بكل ثقه ,, المهم عمنا دا نده عليا ,, ٌولتلوا تعالالي دانتا الي عملته مع الناس انا هعطلعه عليك انهارده ,,
                                        <br/>
                                        لسا متكلمناش قلي معلش اسف هتروح ع شباك زميلتي هي الي هتعمل معاك المقابله ,,  ف سري كدا ,, قولتله فلت مني 
                                        <br/>
                                        استنيت الانسه الي ع اليمين خالص دي ندهت عليا والمقابله ك التالي المقابله كان 90 ف الميه منها الماني لان الالماني بتاعي كان كويس 
                                        <br/>
                                        فكل الي جاي دا هي قالته بالالماني وانا ابلي بترجمه ليكوا 
                                        <br/>
                                        عرف نفسك :: كرتلها الاجابه موووس كانت مبسوطه فشخ اني بتكلم بطلاقه كدا 
                                        <br/>
                                        اسم اخواتك وسنهم وبيدرسوا ايه ,, جاوبت 
                                        <br/>
                                        ولدك و ولدتك بيشتغلوا ايه ,, جاوبت 
                                        <br/>
                                        انت هتعرف تتعامل مع الاولاد // اها طبعا ان ااكبر اخواتي وعندي 2 شباب و بنت يعني هعرف اتعامل مع الجنسين بسهوله 
                                        <br/>
                                        ايه الي يخليك ترجع مصر وانت ممكن تلاقي شغل هناك وتشتغل :: قولتلها سبب ساذج فشخ قولتلها عشان اهلي كبار ف السن ولازم ارجع وبعدين لما اتقن اللغه الالمانيه من خلال سنه الاوبير هلاقي شغل هنا بالهبل مرتبي هيكون هنا ف مصر ع الاقل ب 15 الف جنيه فمش محتاج اقعد ف المانيا وشغلي هنا متوفر ,,, ونسيت اهم اجابه اني كان لازم اقولها اني طالب ف نسه تالته ولازم ارجع عشان اكمل دراستي 
                                        <br/>
                                        ليه عايز تتقن اللغه الالمانيه وعايز توصل لانه مستوي ,,, عايز اتقنه عشان اشغل ف شركات زي فودافون لاني قدمت فيها قبل كدا واترفضت بسبب اني مستوايا قليل ,, وانا اصلا عمري مقدمت فيها ,,, والمستوي الي عايز اوصله b2 اوعي تغلط غلطتي وتقولها ال بي تو بس ,, قولها c1 ولا c2  لاني اتغابيت هي كانت ممكن تقولي منتا الي خلاك توصل ف مصر  لb1 متذاكر هنا b2 وخلاص فانت قولها مستوي عالي فشخ صعب تخده ف مصر ,,
                                        <br/>
                                        اسم العائله الاب و الام و الاولاد وسنهم وعنوان العائله ودا اتسالتهم شفوي  و ورقي 
                                        <br/>
                                        وهي عصرتني ف كام سؤال تاني بس حقيقي مش متذكر اول ما افتكر هرجع اكتبهم 
                                        <br/>
                                        بالنسبه للاسئله الي ع الورقه ::
                                        <br/>
                                        بتاخد ورقه فيها بتاع 20 سؤال ,, كلهم روتنين جدا زي الي اتسالتهم شفوي ,, بس عايزك تخلي بالك من كام سؤال فيهم ,, في سؤال ف الوش الاول من الورقه بيسالك انت تعرف حد ف المانيا او ليك صديقه ف المانيا ,, تقول لا معرفش غير العائله بس ,, بالنسبه للبنات هيكون كاتب صديق ,,
                                        <br/>
                                        اخر سؤال خالص هيقولك ناوي تعمل ايه بعد ما تعمل سنه الاوبير ف المانيا ,, هتقول نفس الي انت كاتبه ف الموتيفيشن لتر مهم جدا تكتبه بوضوح ويكون هو هو عشان ميقولش انت حد كاتبلك الموتيشفيتن لتر وباقي الاسئله عاديه اسمك وسنك واخواتك وعنوان العائله ,,الخ 
                                        <br/>
                                        ______________________________________
                                        <br/>
                                        الملاحظات 
                                        <br/>
                                        1 _ حضر كل نقاط ضعفك قبل الانترفيو كويس وحضرلها اجابت مقنعه انا مثلا كان نقطعه ضعفي ,, انا شاب ولسا بدرس ومفهمش ف شغل البيت 
                                        <br/>
                                        فكان الرد ,, اني عايز اتعلم اللغه لاني اصلا it  واللغه م ال it هقدر اشتغل بيها ف بوزشن محترم ف فودافون  ع عكس الكول سنتر العادي ,, وان الاطفال كلهم شباب وهنكون شباب مع بعض ودا اكتر حاجه العائله محتاجها اكتر من شغل البيت 
                                        <br/>
                                        2 _ نام كويس جدا قبل المقابله ,, احفظ العقد صم هتتسال في كل حرف فيه اسم العائله وعنوانهم وعدد الاطقال واسم الاطفال واعمارهم وهتقبض كام ,,,ألخ اوعي متحفظهاش زميلتنا راحت ومراجعتش الكلام دا وابو نضاره اتريق عليها قال لها اومال انتي عرفا ايه 
                                        <br/>
                                        واحفظ الموتيفيشن لتر بتاعك لانك ف المقابله هتجاوب منه ورقي او شفوي 
                                        <br/>
                                        3 _ مهم جدا تكون واخد كورسات سواء اون لاين او ف الهلال الاحمر ف الاسعافات الاوليه لان دا ميزه ليك انك هتعرف تسعف الاولاد او العائله لو حصل حاجه دا هيقوي موقفك لو انت بتدور ع عائله لانهم هتقولهم كدا وهتبعتلهم الشهادات و هيقوي موقفك قدام مكتب الاجانب و السفاره 
                                        <br/>
                                        4 _ الفيزا بتاعتنا مش بتتاخر انا جاتلي ف 3 ايام بس لو اتفكرت حاجه تانيه هكتبها
                                        <br/>
                                        5 _ اخيرا معاك لحد سنه 27 ومش بيفرق طالب او متخرج
                                        <br/>
                                        ______________________________________
                                        <br/>
                                        ______________________________________
                                        <br/>
                                        لينكات زياده
                                        <br/>
                                        <div className='lft'>
                                            <a target='_blank' rel='noreferrer' href='https://www.aupair.com'>aupair.com</a>
                                        <br/>
                                            <a target='_blank' rel='noreferrer' href='https://www.greataupair.com'>greataupair.com</a>
                                        <br/>
                                            <a target='_blank' rel='noreferrer' href='https://www.Goaupair.com'>goaupair.com</a>
                                        <br/>
                                            <a target='_blank' rel='noreferrer' href='https://www.aupaircare-germany.de'>aupaircare-germany.de</a>
                                        <br/>
                                            <a target='_blank' rel='noreferrer' href='https://www.findaupair.com'>findaupair.com</a>
                                        </div>
                                    </h3>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </main>
            </article>
        );
    }
}

export default Aupair;