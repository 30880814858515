import React from "react";
import './Zertifikat.css';

class Zertifikat extends React.Component {
    render() {
        return (
            <article className="article br3 ba b--black-10 w-100 w-50-m w-50-l mw7 shadow-5 center">
                <main className="pa4 black-80">
                    <div className="measure">
                        <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
                            <div className='legend center'>
                                <legend className="f1 fw6 ph0 mh0">حجز امتحانات شهادات اللغة الألمانية و الإنجليزية المعتمدة في مصر</legend>
                            </div>
                            <hr/>

                            <div className="ui form ma0">
                                <div className="fields">
                                    <h3>
                                        السلام عليكم .. الفترة اللى فاتت أكتر من واحد سأل عن شهادات اللغة الالمانية المعتمدة فى مصر و هل فيه كذة شهادة و ايه اسم الشهادة و امتحنها فين
                                        <br/>
                                        .فى البوست دة هذكر فيه اسماء الشهادات المعتمدة و ايه الأماكن اللى تقدر تمتحن فيها
                                        <br/>
                                        ______________________________________
                                        <br/>
                                        #Goethe_Zertifikat
                                        <br/>
                                        طبعا الكل عارف المعهد و شهادته و ان الشهادة معتمدة لدى الجامعات الألمانية كقبول مشروط او قبول نهائي
                                        <br/>
                                        المعهد امتحاناته في القاهرة و الإسكندرية و كل معهد له مواعيد غير التاني و كذلك في طريقة الحجز
                                        <br/>
                                        <br/>
                                        - أولا فرع القاهرة -
                                        <br/>
                                        مواعيد الامتحانات و طريقة الحجز من <a target='_blank' rel='noreferrer' href='https://www.goethe.de/ins/eg/ar/sta/kai/prf.html'>الموقع</a> دة
                                        <br/>
                                        المستوى الأول طريقة حجز الامتحان فقط من مقر المعهد في الدقى والحجز فقط في الأيام المحددة لحجز الامتحان
                                        <br/>
                                        اما من المستوى الثانى للسادس فطريقة الحجز فقط عن طريق الاستمارة الالكترونية من موقع المعهد و أيضا كل امتحان له مواعيد محددة فلازم تعرف مواعيد الحجز للامتحان اللى عايز تمتحنه
                                        <br/>
                                        السؤال المشهور .. احجز الامتحان ازاى ؟
                                        <br/>
                                        - كل مستوى له مواعيد حجز .. و موجود على موقع المعهد كيفية حجز الامتحان و هل حجز الامتحان من المعهد نفسه او اونلاين
                                        <br/>
                                        و لو فيه استفسار فممكن تبعت ميل ليهم او تتصل و هيردوا عليك بالاجابة الكاملة
                                        <br/>
                                        .....................
                                        <br/>
                                        - ثانيا فرع الاسكندرية -
                                        <br/>
                                        مواعيد الامتحانات و طريقة الحجز من <a target='_blank' rel='noreferrer' href='https://www.goethe.de/ins/eg/ar/sta/alx/prf.html'>الموقع</a> دة
                                        <br/>
                                        طريقة حجز الامتحان عكس القاهرة تماما
                                        <br/>
                                        لان الحجز فقط من مقر المعهد في الإسكندرية لكل المستويات ومواعيده للحجز مش محددة بس له ميعاد نهائي للحجز
                                        <br/>
                                        " نركز يا جماعة ان فيه مواعيد و سعر لطلاب المعهد نفسه و مواعيد و سعر للطلاب من خارج المعهد و دة فى الفرعين "
                                        <br/>
                                        - نماذج الامتحانات لكل مستوى فى موقع جوتة
                                        <br/>
                                        .............................................
                                        <br/>
                                        2 - امتحان شهادة الدبلومة النمساوية
                                        <br/>
                                        #ÖSD_Zertifikat
                                        <br/>
                                        شهادة الدبلومة النمساوية معترفة في السفارة الألمانية في القاهرة و الجامعات الألمانية بتقبل بيها للقبول المشروط اما القبول النهائي فلازم تتأكد بتطلبه والا لاء
                                        <br/>
                                        و مفيش غير معهد ÄDK
                                        <br/>
                                        بيقدم امتحان الشهادة دى و المعهد مقره في مدينة نصر في القاهرة
                                        <br/>
                                        - الحجز فى. اى وقت مش مقيد بمواعيد ولما العدد بيكتمل فالحجز بيقفل
                                        <br/>
                                        - مواعيد الامتحانات للمستويات في الصورة تحت
                                        <br/>
                                        دة <a target='_blank' rel='noreferrer' href='https://www.adkcentral.com/index.php?p_no=25311&lang=1&logo_src=adk_logo.png&background_src=adk_background.jpg'>لينك</a> فيه أسعار الامتحانات في المعهد لكل مستوى
                                        <br/>
                                        - صلاحية الشهادة يفضل تسألوا المعهد نفسه
                                        <br/>
                                        نماذج الامتحانات لكل مستوى <a href='http://www.osd.at'>هنا</a>
                                        <br/>
                                        - الامتحان المتشابهة ما بين ÖSD و جوتة
                                        <br/>
                                        هو مستوى B1 فقط
                                        <br/>
                                        لانهم نفس الشهادة و نفس نموذج الامتحان و نفس كتب التحضير
                                        <br/>
                                        .............................................
                                        <br/>
                                        3 - امتحان شهادة التيليك
                                        <br/>
                                        #Telc_Zertifikate
                                        <br/>
                                        والان متاح امتحان لمستوى
                                        B1 - B2 - C1
                                        <br/>
                                        فى معهد MIG
                                        <br/>
                                        .............................................
                                        <br/>
                                        4 - شهادة التيست دااف
                                        <br/>
                                        #TESTDAF
                                        <br/>
                                        الامتحان في جوتة القاهرة و الإسكندرية و الحجز اونلاين في المعهدين و تفاصيل الحجز على موقع المعهد
                                        <br/>
                                        و ايضا فى DAAD
                                        <br/>
                                        الجامعات الالمانية توافق بهذه الشهادة للقبول النهائى و بدأ الدراسة فى الجامعة .. و يفضل تحضر للامتحان بعد مستوى
                                        <br/>
                                        B2 / C1
                                        <br/>
                                        .............................................
                                        <br/>
                                        كذة معلومة لانها بدأت تتسأل كتير ..
                                        <br/>
                                        = اللغة الألمانية تتكون من كام مستوى ؟
                                        <br/>
                                        - من 6 مستويات
                                        <br/>
                                        اقلها A1 المستوى الأول
                                        <br/>
                                        و أعلاها C2 المستوى السادس
                                        <br/>
                                        = هل فيه معاهد معتمدة في مصر للغة الالمانية ؟
                                        <br/>
                                        - معهد جوتة فقط
                                        <br/>
                                        = هل لو درست في معهد جوتة هاخد شهادة معتمدة ؟
                                        <br/>
                                        - لاء مش هتاخد شهادة معتمدة الا لو امتحنت امتحان المستوى زى اى طالب مدرسش في المعهد
                                        <br/>
                                        = ينفع ادرس في معهد غير جوتة او مع مدرس او ذاتى و امتحن في جوتة ؟
                                        <br/>
                                        - اة عادى جدا
                                        <br/>
                                        = لازم امتحن كل مستوى ؟
                                        <br/>
                                        - لاء مش لازم تمتحن كل مستوى .. مثلا لو عايز تمتحن المستوى الثالث او الرابع فمش لازم تمتحن المستوى الأول و الثانى و هكذا
                                        <br/>
                                        = صلاحية الشهادة ؟
                                        <br/>
                                        - شهادات Goethe-Institut لإمتحانات Goethe-Zertifikate A1-C2 لا تفقد سريانها، ولكن هناك الكثير من المؤسسات التي لا تقبل شهادات الإمتحانات إذا مرّ عليها أكثر من عامين.
                                        <br/>
                                        ............................
                                        <br/>
                                        <a target='_blank' rel='noreferrer' href='/sprache/wegzulernen'>ازاى اتعلم اللغة الألمانية</a>
                                        <br/>
                                        ______________________________________
                                        <br/>
                                        ثانيا - شهادات اللغة الانجليزية المعتمدة فى مصر
                                        <br/>
                                        - شهادة الايلتس
                                        <br/>
                                        #الايلتس
                                        <br/>
                                        عشان تمتحن الايلتس و تاخد شهادة معتمدة فمفيش غير المركزين دول فى مصر هما اللى امتحانهم معتمد
                                        <br/>
                                        فى معظم محافظات مصر <a target='_blank' rel='noreferrer' href='https://www.britishcouncil.org.eg/exam/ielts/dates-locations'>British Council</a>
                                        <br/>
                                        (<a target='_blank' rel='noreferrer' href='https://www.idp.com/middleeast/ielts/bookyourtest/cairo'>آي دي بي آيلتس مصر</a> | مركز اختبار الآيلتس ) فى القاهرة و الاسكندرية 
                                        <br/>
                                        ......................
                                        <br/>
                                        #TOEFL
                                        <br/>
                                        الاميد ايست - <a href='http://www.amideast.org/egypt/amideast-tests-and-schedules'>amideast</a>
                                        <br/>
                                        كل اللى عليك ان انت هتختار عايز شهادة أيه " ايلتس والا تويفل" و بتدخل على كل لينك و بتعرف مواعيد الامتحان و التسجيل والشروط و المكان
                                        <br/>
                                        ----
                                        <br/>
                                        - مفيش مكان غير الأماكن دى بتقدم شهادة معتمدة "اللى يعرف مكان معتمد غيرهم ياريت يقول"
                                        <br/>
                                        - مفيش حاجة اسمها وصلت ليفيل 10 مثلا .. لاء انت بتدرس كورس انجليزى عادى و بعد ما تخلص الكورس فبتحضر لأى من الامتحانين دول على حسب اتجاهك
                                        <br/>
                                        - مش لازم تدرس في معهد او مكان معتمد .. لاء عادى ممكن تدرس في اى مكان و تخلص اللغة بس لازم تمتحن في الأماكن اللى قولت عليها عشان تاخد شهادة معتمدة
                                    </h3>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </main>
            </article>
        );
    }
}

export default Zertifikat;
