import React from "react";
import './PrepYear.css';

class PrepYear extends React.Component {
    render() {
        return (
            <article className="article br3 ba b--black-10 w-100 w-50-m w-50-l mw7 shadow-5 center">
                <main className="pa4 black-80">
                    <div className="measure">
                        <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
                            <div className='legend center'>
                                <legend className="f1 fw6 ph0 mh0">السنة التحضيرية: شروط طلاب الثانوية العامة و الدبلومة الامريكية و الثانوية البريطانية</legend>
                            </div>
                            <hr/>

                            <div className="ui form ma0">
                                <div className="fields">
                                    <h3>
                                        لنهاردة هتكلم ع الشباب اللى لسا مخلص ثانوية عامة او دبلومة امريكية او ig و مش عارفة تعمل ايه او هتروح تدرس ايه و هتجيب قبول جامعة ازاى ..
                                        <br/>
                                        انت عشان تسافر دراسة لازم يبقا معاك
                                        <br/>
                                        لغة + قبول جامعة + حساب بنكى فى المانيا ب 10000 يورو او ضامن مالى فى المانيا تنطبق عليه الشروط
                                        <br/>
                                        "مفيش منح بكالوريوس فى المانيا"
                                        <br/>
                                        البوست دة هتكلم عن السنة التحضيرية بالتفصيل
                                        <br/>
                                        ايه هي السنة التحضيرية - مين اللى المفروض يدرسها - التخصصات في السنة التحضيرية - الجامعات اللى بتقدم السنة التحضيرية – التقديم و ايه الشروط – امتحان القبول - تجارب و غيره
                                        <br/>
                                        فلنبدأ
                                        <br/>
                                        .........................
                                        <br/>
                                        - السنة التحضيرية زى اى سنة دراسية في ألمانيا وهى عبارة عن فصلين دراسيين و هي سنة الزامية لجميع الطلاب المصريين و أغلب طلاب الدول العربية الحاصلين على شهادة الثانوية فقط و بعد انتهاء السنة التحضيرية يحق لهم ان هما يقدموا في الجامعة لاستكمال مرحلة البكالوريوس
                                        <br/>
                                        .........................
                                        <br/>
                                        - طب أعرف منين ان انا ليا سنة تحضيرية
                                        <br/>
                                        مثلا الثانوية المصرية لازم يكون مجموعك فوق ال 60% في الثانوية و لو اقل مينفعش تدرس في المانيا تحضيرية
                                        <br/>
                                        %و في بعض الدول العربية يكفى ان مجموعك يكون فوق ال 50
                                        <br/>
                                        و في بعض الدول العربية ليهم الحق ان هما مياخدوش السنة التحضيرية و يقدموا مباشرة على البكالوريوس
                                        <br/>
                                        هتدخل على <a target='_blank' rel='noreferrer' href='https://anabin.kmk.org/no_cache/filter/schulabschluesse-mit-hochschulzugang.html#land_gewaehlt'>الموقع</a> دة و تختار الدولة اللى انت اخدت منها شهادة الثانوية و تشوف شروطها
                                        <br/>
                                        و دة <a target='_blank' rel='noreferrer' href='https://www.facebook.com/100003344008216/videos/1129715000483289'>فيديو</a> لمحمد عبدالسلام بيشرح فيه الكلام دة
                                        <br/>
                                        .........................
                                        <br/>
                                        - أنواع السنة التحضيرية
                                        <br/>
                                        السنة التحضيرية متقسمة لكذة تخصص و على حسب انت عايز تدرس ايه بعد التحضيرية
                                        <br/>
                                        و دى التخصصات
                                        <br/>
                                        = The university Studienkollegs :
                                        <br/>
                                        M-course: for medical, biological and pharmaceutical degrees
                                        <br/>
                                        T-course: for mathematical, science or technical degrees
                                        <br/>
                                        W-course: for business, economics, and social science degrees
                                        <br/>
                                        G-course: for humanity degrees or German studies
                                        <br/>
                                        S-course: for language degrees
                                        <br/>
                                        = The university of applied sciences Studienkollegs :
                                        <br/>
                                        TI-course: for technical and engineering degrees
                                        <br/>
                                        WW-course: for economics and business degrees
                                        <br/>
                                        GD-course: for design and art degrees
                                        <br/>
                                        SW-course: for social science degrees
                                        <br/>
                                        مثلا لو عايز تدرس هندسة فهى ليها تحضيرية لازم تدخلها و لو عايز تدرس مجال طبى فلازم تدرس تحضيرية خاص للمجال دة و دة طبعا هيرجع لنوع الثانوية اللى معاك يعنى لو شهادتك أدبى فمينفعش تقدم على تحضيرية للهندسة
                                        <br/>
                                        لو محتار و مش عارف تقرر هل التخصص اللى عايز تدرسه تبع انهى تحضيرية فراسل الجامعة اللى فيها تخصصك و إعرف منهم
                                        <br/>
                                        .........................
                                        <br/>
                                        - الفرق بين الجامعة و الجامعة التطبيقية في السنة التحضيرية
                                        <br/>
                                        الفرق ان لو درست السنة التحضيرية في الجامعة فليك الحق ان انت تدرس البكالوريوس في التطبيقية و الجامعة العادية
                                        <br/>
                                        و لو درست السنة التحضيرية في التطبيقية فمالكش الحق تدرس البكالوريوس في الجامعة العادية
                                        <br/>
                                        طب جبت الكلام دة منين
                                        <br/>
                                        الكلام دة موجود على <a target='_blank' rel='noreferrer' href='https://www.studienkollegs.de/types%20of%20Studienkollegs.html'>الموقع الرئيسى</a> الخاص للسنة التحضيرية
                                        <br/>
                                        و دة <a target='_blank' rel='noreferrer' href='https://www.facebook.com/groups/311111149267612/permalink/786385365073519/'>بوست</a> فيه مقارنة بين الجامعة العادية و الجامعة التطبيقية
                                        <br/>
                                        .........................
                                        <br/>
                                        - الاوراق المطلوبة للتقديم على السنة التحضيرية
                                        <br/>
                                        شهادة الثانوية
                                        <br/>
                                        +
                                        <br/>
                                        شهادة لغة ألمانى لمستوى
                                        B1 or B2
                                        <br/>
                                        و دة على حسب الجامعة هتطلب ايه
                                        <br/>
                                        - السنة التحضيرية بالألمانى اة والله بالألمانى و مفيش حاجة اسمها تحضيرية بالإنجليزى وممكن بعد ما تخلصها تدرس بكالوريوس بالانجليزى او بالألمانى عادى
                                        <br/>
                                        : عشان تقدم على السنة التحضيرية فلازم يكون معاك شهادة الثانوية .. يعنى مينفعش تقدم وانت لسا مخلصتش 3 ثانوى مثلا
                                        <br/>
                                        .........................
                                        <br/>
                                        - الجامعات التى يوجد بها سنة تحضيرية و مواعيد التقديم
                                        <br/>
                                        مش كل الجامعات في المانيا فيها سنة تحضيرية و دة <a target='_blank' rel='noreferrer' href='http://www.studienkollegs.de/Kontaktdaten.html'>موقع</a> للسنة التحضيرية و كاتبين الجامعات اللى فيها التحضيرية
                                        <br/>
                                        فى الموقع مقسمينها ولاية ولاية وكل جامعة فيها تحضيرية كاتبين اسمها و المدينة و نوع الكورس اللى فيها و ليها موقع و من خلاله تقدر تدخل عليه و تعرف شروطها و تعرف الورق اللى المفروض تقدم بيه و تعرف مواعيد التقديم و تعرف عايزين مستوى ايه للتقديم للسنة التحضيرية و تعرف طريقة التقديم ازاى
                                        <br/>
                                        = <a target='_blank' rel='noreferrer' href='https://www.facebook.com/groups/311111149267612/permalink/470177303360995/'>فيديو</a> ازاى تراسل الجامعة و صيغة المراسلة
                                        <br/>
                                        .........................
                                        <br/>
                                        - مصاريف السنة التحضيرية
                                        <br/>
                                        السنة التحضيرية ليها فصلين شتوى و بيبدأ في أكتوبر و صيفى بيبدأ في ابريل
                                        <br/>
                                        و السنة التحضيرية تكلفتها زى الجامعة العادية لو حكومية
                                        <br/>
                                        و بتبقا من 100 ل 400 يورو ما عدا ولاية بادن قررت ان المصاريف تبقا 1500 للترم للطلاب الاجانب من الترم الشتوى 10/2017 و لسا مفيش ولايات تانية قررت رسمى كدة
                                        <br/>
                                        اما الجامعات الخاصة اللى محدش بينصح بيها فبتبقا بالألاف
                                        <br/>
                                        .........................
                                        <br/>
                                        - قدمت للجامعة و اخدت منها قبول
                                        <br/>
                                        خطاب القبول ميعتبرش تاكيد ان انت خلاص هتسافر تدرس السنة التحضيرية
                                        <br/>
                                        لاء هو عبارة عن دعوة لحضور امتحان القبول و اسمه
                                        Aufnahmetest
                                        <br/>
                                        اللى الجامعة هتعمله اللى المفروض تنجح فيه كشرط اساسي عشان تبدأ السنة التحضيرية و هتلاقى مكتوب على موقع كل جامعة امتى امتحان القبول بتاعها و مكانه فين
                                        <br/>
                                        وامتحان القبول هو امتحان لغه المانية فقط وفي بعض الجامعات ممكن يبقا معاه امتحان عن الكيمياء اوالرياضيات
                                        <br/>
                                        و كل جامعة بتبقا كاتبة على موقعها امتحان القبول بيتكون من ايه و بيبقا فيه نماذج عن الامتحانات
                                        <br/>
                                        حاجة كمان مهمة مش عشان نجحت في الامتحان يبقا كدة هتدرس تحضيرية لاء لاء
                                        <br/>
                                        العدد اللى بيقدم بيبقا كتير طبعا وليكن 500
                                        <br/>
                                        و هما عايزين 100 بس
                                        <br/>
                                        فأكيد بياخدوا أعلى 100 فلازم تكون مذاكر كويس
                                        <br/>
                                        - يفضل تجيب قبول من اكتر من جامعة عشان لو منجحتش فى امتحان القبول لجامعة فتقدر تمتحن في التانية و يبقا عندك بديل و متعتمدش على جامعة واحدة بس
                                        <br/>
                                        - لو منجحتش فى امتحان القبول و مدخلتش التحضيرية فممكن تدرس لغة وتقدم تانى فى الترم اللى بعده
                                        <br/>
                                        .........................
                                        <br/>
                                        - الامتحان النهائي للسنة التحضيرية
                                        <br/>
                                        Feststellungsprüfung “FSP”
                                        <br/>
                                        و دة بتمتحن فيه كل المواد اللى درستها خلال السنة
                                        <br/>
                                        ولو سقطت فيه مرتين هيبقا فيه مشكلة بالنسبالك و ممكن تترحل
                                        <br/>
                                        و المواد اللى بتدرسها في السنة التحضيرية فبتختلف ما بين التخصص للتانى و كل جامعة بتبقا كاتبة ايه هي المواد اللى في التخصص دة و الالمانى حاجة أساسية و مبتاخدش كورس لانك بتدرسها خلال السنة من ضمن المواد و اخر السنة بتمتحن فيها عادى و يبقا كدة بالنسبالك انت خلصتها
                                        <br/>
                                        .........................
                                        <br/>
                                        = مينفعش تحول بعد الترم الأول من جامعة لجامعة اثناء التحضيرية
                                        <br/>
                                        <a target='_blank' rel='noreferrer' href='http://www.studienkollegs.de/FAQ.html'>studienkollegs.de/FAQ.html</a>
                                        <br/>
                                        .........................
                                        <br/>
                                        تقدير الثانوية و التحضيرية مهم ؟
                                        <br/>
                                        اة التقدير للاتنين مهم لان بعد نهاية السنة التحضيرية بياخدوا المتوسط بين معدلك في الثانوية العامة والسنة التحضيرية وبيبقا دة المعدل النهائي يعنى معدلك في الثانوية العامة يؤثر بنسبة %50
                                        <br/>
                                        و فيه جامعات بتهتم بمجموع السنة التحضيرية اكتر من الثانوية
                                        <br/>
                                        اكتر سؤال بيتسأل الطب و الهندسة او اى مجال عموما له تقدير معين ؟
                                        <br/>
                                        الطب و المجال الطبى عموما اة و يفضل اللى يقدم على اى مجال طبى يكون مجموعه في التسعينات في الثانوية و يجيب زيهم في التحضيرية لان المجال الطبي عموما اكتر مجال عليه ضغط هناك
                                        <br/>
                                        <a target='_blank' rel='noreferrer' href='https://www.facebook.com/groups/311111149267612/permalink/426809481031111/'>facebook.com/../../../426809481031111</a>
                                        <br/>
                                        الهندسة و اى مجال تانى في الغالب لاء لان مفيش عليها ضغط اوى بس بتختلف في الجامعات الكبيرة لان كل ما مجموعك كان عالى فبتقدر تقدم على جامعات اعلى و عليها ضغط و بيختاروا طبعا الأعلى
                                        <br/>
                                        .........................
                                        <br/>
                                         طالب الثانوية البريطانية يدخل على
                                        anabin
                                        <br/>
                                        و يختار البلد المانحة للشهادة (بريطانيا)
                                        <br/>
                                        special requirementsو هيقولك كل ال 
                                        <br/>
                                        لكل مجال
                                        <br/>
                                        = البوست دة كويس هيفيدكم
                                        <br/>
                                        <a target='_blank' rel='noreferrer' href='https://www.facebook.com/groups/311111149267612/permalink/467534736958585/'>facebook.com/../permalink/467534736958585</a>
                                        <br/>
                                        <a target='_blank' rel='noreferrer' href='https://www.facebook.com/groups/311111149267612/permalink/548987398813318/'>facebook.com/../permalink/548987398813318</a>
                                        <br/>
                                        .........................
                                        <br/>
                                        - طلاب الدبلومة الامريكية
                                        <br/>
                                        تقدر تدخل على anabin
                                        <br/>
                                        و تختار البلد المانحة للشهادة (امريكا) و هيقولك كل ال special requirements لكل مجال
                                        <br/>
                                        <a target='_blank' rel='noreferrer' href='https://www.facebook.com/groups/311111149267612/permalink/484651938580198/'>الشروط</a> على موقع انبيان فى اكتوبر/2017 لطلاب الدبلومة الامريكية فى مصر
                                        <br/>
                                        .........................
                                        <br/>
                                        دة <a target='_blank' rel='noreferrer' href='https://www.facebook.com/ASEGYDE/videos/352638635117443/'>فيديو</a> مفيد جدا و بيتكلم عن السنة التحضيرية و عن الامتحان
                                        <br/>
                                        <a target='_blank' rel='noreferrer' href='https://www.facebook.com/permalink.php?story_fbid=955360947918696&id=100003344008216'>فيديو محمد عبدالسلام</a> مع طالب مر بالتجربة
                                        <br/>
                                        <a target='_blank' rel='noreferrer' href='https://www.facebook.com/groups/311111149267612/permalink/449184412126951/'>تجربة</a> طالب فى امتحان قبول التحضيرية
                                        <br/>
                                        <a target='_blank' rel='noreferrer' href='https://www.facebook.com/groups/311111149267612/permalink/453796654999060/'>تجربة</a> طالب درس فى تحضيرية خاصة
                                        <br/>
                                        <a target='_blank' rel='noreferrer' href='https://www.dropbox.com/sh/b17elx0bs8viw3y/AADjiaUXyciIRvKPWKiOTZn1a?dl=0'>فايل</a> فيه بعض التجارب و الامتحانات لبعض الجامعات و الكتب لامتحان للسنة التحضيرية
                                        <br/>
                                        امتحان القدرات <a target='_blank' rel='noreferrer' href='https://www.facebook.com/groups/311111149267612/permalink/471750286537030/'>TestAS</a>
                                        <br/>
                                        ...................
                                        <br/>
                                        طب
                                        طب ازاى ألغى السنة التحضيرية و أسافر بكالوريوس مباشرة ؟
                                        <br/>
                                        - الطلاب اللى معاهم ثانوية مصرية و عايزة تسافر على بكالوريوس و تلغى السنة التحضيرية فلازم تاخد سنة "خارج المانيا" في الجامعة و تخلصها في نفس المجال او مجال قريب من اللى عايز تدرسه هناك في المانيا
                                        <br/>
                                        و في بعض الدول العربية لازم تدرس سنتين جامعة عشان تلغى التحضيرية فحاول تشوف على موقع انبيان و هتلاقى اللينك في اول البوست فوق
                                        <br/>
                                        والسنة دى يفضل تخلصها صافى و حتى لو شيلت مادة فحاول برضه تسأل الجامعة لان فيه جامعات بتوافق و جامعات بترفض
                                        <br/>
                                        و التقدير فى السنة دى يعتبر زى التحضيرية بالظبط
                                        <br/>
                                        يعنى هياخدوا المتوسط ل اول سنة جامعة + ثانوية
                                        <br/>
                                        لو انت علمى رياضة مثلا و دخلت حقوق او تجارة و درست سنة فمينفعش تسافر تدرس هناك هندسة من غير ما تاخد سنة تحضيرية لان المجال اللى درسته غير المجال اللى عايز تدرسه
                                        <br/>
                                        ........................
                                        <br/>
                                        طب فيه حل ان الطالب يدرس السنة التحضيرية فى مصر من غير يدخل جامعة و هو ان يدرس السنة التحضيرية فى <a target='_blank' rel='noreferrer' href='https://www.goethe.de/ins/eg/ar/spr/stu/kolleg.html'>معهد جوتة</a> للتفاصيل اكتر فالأفضل تتصل بمعهد جوتة و تعرف مصاريفها و شروطها و متاح برامج ايه
                                        <br/>
                                        .....................
                                        <br/>
                                        هو بوست طويل شوية انا عارف .. يارب يكون فايدة
                                    </h3>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </main>
            </article>
        );
    }
}

export default PrepYear;
